import { useRoutes } from "react-router-dom";
import Home from "../Pages/First/Home";
import Terms from "../Pages/Terms";
import TermsCo from "../Components/Terms/TermsCo";
import Privacy from "../Components/Terms/Privacy";
import Cookies from "../Components/Terms/Cookies";
import Vendor from "../Components/Vendors/Vendor";
import Reservation from "../Reservation/Pages/Reservation";
import Menu from "../Reservation/Pages/Menu";
import NotFound from "../Reservation/Pages/NotFound";
import Reserved from "../Reservation/Pages/Reserved";
import Blog from "../Pages/Blog";
import SingleBlog from "../Components/Blog/SingleBlog";
import ReservedOnline from "../Reservation/Pages/ONLINE/ReservedOnline";
import ReservedWalkin from "../Reservation/Pages/WALKIN/ReservedWalkin";
import EventOverview from "../Components/Events/EventOverview";
import Event from "../Pages/Event/Event";
import SingleEvent from "../Pages/Event/SingleEvent";
import EventCheck from "../Components/Events/EventCheck";




function Routes() {
  let element = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
path:'*',
element:<NotFound/>
    },
    {
path:'/reserved',
element:<Reserved/>
    },
    {
path:'/events',
element:<Event/>
    },
    {
path:'/event/:id',
element:<SingleEvent/>
    },

    // FOR WIDGET ========================
    {
path:'/event-details/:vendor/:id',
element:<EventOverview/>
    },
    {
path:'/event/payment_check',
element:<EventCheck/>
    },
    {
path:'/reserved-online',
element:<ReservedOnline/>
    },
    {
path:'/reserved-walkin',
element:<ReservedWalkin/>
    },
    {
path:'/blogs',
element:<Blog/>
    },
    {
path:'/blog/:slug',
element:<SingleBlog/>
    },
    {
path:'/not-found',
element:<NotFound/>
    },
    {
      path: "/vendor",
      element: <Vendor />,
    },
    {
      path: "/:name/:uid",
      element: <Reservation/>,
    },
    {
      path: "/reservation/:name/:uid",
      element: <Menu/>,
    },
    {
      element: <Terms />,
      children: [
        {
          path: "/terms-and-condition",
          element: <TermsCo />,
        },
        {
          path: "/privacy-policy",
          element: <Privacy />,
        },
        {
          path: "/cookies-policy",
          element: <Cookies />,
        },
      ],
    },
  ]);

  return element;
}

export default Routes;

import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  IconButton,
  Typography,
  Paper,
  Button,
  InputLabel,
  TextField,
  InputAdornment,
  Skeleton,
  CircularProgress,
  LinearProgress,
  ListItemButton,
} from "@mui/material";
import backIcon from "../Assets/img/back.svg";
import deleteIcon from "../Assets/img/delete.svg";
import minus from "../Assets/img/minus.svg";
import plus from "../Assets/img/plus.svg";
import minus2 from "../Assets/img/minus2.svg";
import plus2 from "../Assets/img/plus2.svg";

import ScrollToTop from "../ScrollToTop";
import { getReservationDetails } from "../../assets/img/api";
import digitalIcon from "../Assets/img/digital.svg";
import offlineIcon from "../Assets/img/offline.svg";
import digitalIcon2 from "../Assets/img/active-digital.svg";
import offlineIcon2 from "../Assets/img/active-offline.svg";
import OverviewOnline from "./ONLINE/OverviewOnline";
import OverviewWalkin from "./WALKIN/OverviewWalkin";

const Information = ({
  handleBack,
  selectedMenuList,
  menuList,
  increaseQuantity,
  decreaseQuantity,
  data,
  deleteMenuItem,
  uid,
  darkMode,
  source,
  walkin,
}) => {
  console.log(typeof walkin, walkin, "Walking");
  const [matchingDay, setMatchingDay] = useState(null);
  const [availableTime, setAvailableTime] = useState([]);
  const [selectTime, setSelectedTime] = useState(null);
  const [selectDate, setSelectedDate] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [error, setError] = useState(false);

  const { consumption_tax, value_added_tax } = data?.tax;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setError(!validateEmail(value)); // Set error if email is not valid
  };

  const [nextSevenDays, setNextSevenDays] = useState([]);

  useEffect(() => {
    const currentDate = new Date(); // Get current date
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const getNextDate = (date, daysToAdd) => {
      const result = new Date(date);
      result.setDate(result.getDate() + daysToAdd);
      return result;
    };

    const generateNextSevenDays = () => {
      const nextDays = [];
      for (let i = 0; i < 7; i++) {
        const nextDate = getNextDate(currentDate, i);
        nextDays.push({
          day: days[nextDate.getDay()],
          month: months[nextDate.getMonth()],
          date: nextDate.getDate(),
          fullDate: `${nextDate.getFullYear()}-${(
            "0" +
            (nextDate.getMonth() + 1)
          ).slice(-2)}-${("0" + nextDate.getDate()).slice(-2)}`,
        });
      }
      setNextSevenDays(nextDays);
    };

    generateNextSevenDays();
  }, []);
  console.log(nextSevenDays);

  useEffect(() => {
    if (menuList?.length === 0) {
      handleBack();
    }
  }, [menuList]);

  const checkSchedule = () => {
    const now = new Date();
    const currentDay = now.toLocaleString("en-US", { weekday: "long" });
    const currentTime = `${String(now.getHours()).padStart(2, "0")}:${String(
      now.getMinutes()
    ).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`;

    const matchingDay = data?.opening_hours?.find(
      (item) => item.day === currentDay
    );

    setMatchingDay(matchingDay);
  };

  useEffect(() => {
    checkSchedule();
  }, [data]);

  const timeToMinutes = (time) => {
    const [hours, minutes, seconds] = time?.split(":").map(Number);
    return hours * 60 + minutes;
  };

  // Function to convert minutes to time string with AM or PM suffix
  const minutesToTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours < 12 ? "am" : "pm";
    const displayHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
    return `${String(displayHours).padStart(2, "0")}:${String(mins).padStart(
      2,
      "0"
    )}${period}`;
  };

  const now = new Date();
  const currentMinutes = now.getHours() * 60 + now.getMinutes();

  // Parse open and close times

  useEffect(() => {
    setSelectedDate(matchingDay);
  }, [matchingDay, data]);

  const [selectedOpeningHour, setSelectedOpeningHour] = useState(null);

  useEffect(() => {
    const item = data?.opening_hours?.find(
      (item) => item.day === selectDate?.day
    );

    setSelectedOpeningHour(item);

    if (matchingDay && matchingDay === item) {
      const openTime = timeToMinutes(matchingDay?.open_time);
      const closeTime = timeToMinutes(matchingDay?.close_time);

      // Initialize array to store available intervals
      const availableIntervals = [];

      // Loop through the time range in 30-minute intervals
      for (let i = openTime; i <= closeTime; i += 30) {
        // Convert the time to 12-hour and 24-hour formats
        const time12Hour = minutesToTime(i);
        const time24Hour = `${String(Math.floor(i / 60)).padStart(
          2,
          "0"
        )}:${String(i % 60).padStart(2, "0")}`;

        // Add the time to the available times array
        availableIntervals.push({ time12Hour, time24Hour });
      }

      // Set the available times state
      setAvailableTime(availableIntervals);
    } else {
      if (item) {
        const openTime = timeToMinutes(item?.open_time);
        const closeTime = timeToMinutes(item?.close_time);

        // Initialize array to store available intervals
        const availableIntervals = [];

        // Loop through the time range in 30-minute intervals
        for (let i = openTime; i <= closeTime; i += 30) {
          // Convert the time to 12-hour and 24-hour formats
          const time12Hour = minutesToTime(i);
          const time24Hour = `${String(Math.floor(i / 60)).padStart(
            2,
            "0"
          )}:${String(i % 60).padStart(2, "0")}`;

          // Add the time to the available times array
          availableIntervals.push({ time12Hour, time24Hour });
        }

        // Set the available times state
        setAvailableTime(availableIntervals);
      }
    }
  }, [data, matchingDay, selectDate]);

  const [guestSize, setGuestSize] = useState(1);

  const calculateTotalUnitPrice = (data) => {
    let totalPrice = 0;
    data.forEach((item) => {
      totalPrice += item.quantity * item.unit_price;
    });
    return totalPrice;
  };

  const [guestLimit, setGuestLimit] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetReservationDetails = async () => {
    setIsLoading(true);

    await getReservationDetails(data?.id, selectedMenuList)
      .then((res) => {
        setIsLoading(false);
        setGuestLimit(res?.data?.data?.additional_guest);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetReservationDetails();
  }, [menuList, selectedMenuList]);

  // const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();
  const currentTime24Hour = `${currentHour
    .toString()
    .padStart(2, "0")}:${currentMinute.toString().padStart(2, "0")}`;

  // Filter time objects that are greater than current time
  const upcomingTimes = availableTime.filter(
    (time) => time.time24Hour > currentTime24Hour
  );

  const [added_tax, setAdded_tax] = useState(0);
  const [consume_tax, setConsume_tax] = useState(0);

  useEffect(() => {
    if (consumption_tax === 1) {
      const consume_tax = (5 / 100) * calculateTotalUnitPrice(menuList);
      setConsume_tax(consume_tax);
    } else {
      setConsume_tax(0);
    }

    if (value_added_tax === 1) {
      const added_tax = (7.5 / 100) * calculateTotalUnitPrice(menuList);
      setAdded_tax(added_tax);
    } else {
      setAdded_tax(0);
    }
  }, [data, menuList, selectedMenuList]);

  const mappedTime =
    matchingDay === selectedOpeningHour ? upcomingTimes : availableTime;

  const [selectedPayment, setSelectedPayment] = useState(null);
  return (
    <>
      {activeStep === 0 && (
        <>
          <Box
            sx={{
              bgcolor: darkMode ? "#000" : "#fff",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              width: { lg: "30%", md: "40%", sm: "50%", xs: "100%" },
              margin: "0 auto",
              height: "130px",
              zIndex: 1000,
            }}
          >
            <Box sx={{ pt: 5, px: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                <IconButton onClick={handleBack}>
                  <img src={backIcon} />
                </IconButton>

                <Typography
                  className={darkMode ? "dark" : "light"}
                  sx={{ color: "#151515", fontSize: "16px", fontWeight: 700 }}
                >
                  Reservation Information
                </Typography>
              </Box>
              <Typography sx={{ mt: 2, color: darkMode ? "#fff" : "#5b5b5b" }}>
                Provide more details to place reservation
              </Typography>
            </Box>
          </Box>
          <Box
            className={darkMode ? "dark" : "light"}
            sx={{ px: 3, mt: 15, pt: 3 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                className={darkMode ? "dark" : "light"}
                sx={{ fontWeight: 600, fontSize: "12px" }}
              >
                Menu Details
              </Typography>

              <Button
                onClick={handleBack}
                sx={{
                  fontSize: "11px",
                  textDecoration: "underline",
                  color: darkMode ? "#00D23B" : "#007E23",
                  fontWeight: 600,
                  textTransform: "initial",
                }}
              >
                {" "}
                Add Menu Item
              </Button>
            </Box>

            <Box sx={{ mt: 2 }}>
              {menuList?.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
                  >
                    <IconButton onClick={() => deleteMenuItem(item?.menu_id)}>
                      <img src={deleteIcon} />
                    </IconButton>
                    <Box>
                      <Typography
                        className={darkMode ? "dark" : "light"}
                        sx={{ fontWeight: 700, fontSize: "13px" }}
                      >
                        {item?.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "11px",
                          color: darkMode ? " #fff" : "#5b5b5b",
                        }}
                      >
                        {" "}
                        N{item?.unit_price.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                  <Box align="right">
                    <Box
                      sx={{
                        border: darkMode
                          ? "1px solid #F489FD"
                          : "1px solid #75007E",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "89px",
                        borderRadius: "4px",
                        height: "31px",
                      }}
                    >
                      <IconButton
                        onClick={() => decreaseQuantity(item.menu_id)}
                        sx={{
                          borderRadius: 2,
                          color: "#75007E",
                          fontWeight: 500,
                          py: 1,
                        }}
                      >
                        <img src={minus} />
                      </IconButton>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 700,
                          color: darkMode ? "#F489FD" : "#75007E",
                        }}
                      >
                        {item?.quantity}
                      </Typography>
                      <IconButton
                        onClick={() => increaseQuantity(item.menu_id)}
                        sx={{
                          borderRadius: 2,
                          fontWeight: 500,
                          color: "#75007E",
                          py: 1,
                          fontSize: "14px",
                        }}
                      >
                        <img src={plus} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                borderTop: "1px solid #f4f4f4",
                mt: 4,
                py: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                className={darkMode ? "dark" : "light"}
                sx={{ fontWeight: 500, fontSize: "12px" }}
              >
                Menu Total ({menuList?.length}Items)
              </Typography>
              <Typography
                className={darkMode ? "dark" : "light"}
                sx={{ fontWeight: 700, fontSize: "12px" }}
              >
                N{calculateTotalUnitPrice(menuList).toLocaleString()}
              </Typography>
            </Box>
            <Box
              sx={{
                py: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                className={darkMode ? "dark" : "light"}
                sx={{ fontWeight: 500, fontSize: "12px" }}
              >
                Service Fee
              </Typography>
              <Typography
                className={darkMode ? "dark" : "light"}
                sx={{ fontWeight: 700, fontSize: "12px" }}
              >
                N1,000
              </Typography>
            </Box>
            {value_added_tax === 1 && (
              <Box
                sx={{
                  py: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  className={darkMode ? "dark" : "light"}
                  sx={{ fontWeight: 500, fontSize: "12px" }}
                >
                  VAT - 7.5%
                </Typography>
                <Typography
                  className={darkMode ? "dark" : "light"}
                  sx={{ fontWeight: 700, fontSize: "12px" }}
                >
                  N {parseFloat(added_tax.toFixed(2)).toLocaleString()}
                </Typography>
              </Box>
            )}

            {consumption_tax === 1 && (
              <Box
                sx={{
                  py: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  className={darkMode ? "dark" : "light"}
                  sx={{ fontWeight: 500, fontSize: "12px" }}
                >
                  Consumption Tax - 5%
                </Typography>
                <Typography
                  className={darkMode ? "dark" : "light"}
                  sx={{ fontWeight: 700, fontSize: "12px" }}
                >
                  N{parseFloat(consume_tax.toFixed(2)).toLocaleString()}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                borderTop: "1px solid #f4f4f4",

                py: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  color: darkMode ? "#EA8072" : "#A71200",
                }}
              >
                Total Fee
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "12px",
                  color: darkMode ? "#EA8072" : "#A71200",
                }}
              >
                N
                {(
                  calculateTotalUnitPrice(menuList) +
                  1000 +
                  added_tax +
                  consume_tax
                ).toLocaleString()}
              </Typography>
            </Box>
          </Box>

          {walkin ? (
            <>
              <Box
                sx={{
                  borderTop: darkMode
                    ? "5px solid #151515"
                    : "5px solid #dedede",
                  mt: 5,
                  px: 3,
                  pt: 4,
                }}
              >
                <Typography
                  className={darkMode ? "dark" : "light"}
                  sx={{ fontSize: "14px", fontWeight: 700 }}
                >
                  Contact Details
                </Typography>

                <Typography
                  sx={{
                    color: darkMode ? "#fff" : "#5b5b5b",
                    mt: 1,
                    fontSize: "13px",
                  }}
                >
                  Please provide the following information for your order.
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <InputLabel
                    className={darkMode ? "dark" : "light"}
                    sx={{ fontSize: "12px", mb: 0.5, fontWeight: 500 }}
                  >
                    Full Name
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    placeholder="Enter your full name"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        border: darkMode
                          ? "1px solid #fff"
                          : "1px solid #151515",
                        color: darkMode ? "#fff" : "#151515",
                        fontSize: "13px",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <InputLabel
                    className={darkMode ? "dark" : "light"}
                    sx={{ fontSize: "12px", mb: 0.5, fontWeight: 500 }}
                  >
                    Email Address
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={email}
                    error={error}
                    helperText={error ? "Not a valid email" : ""}
                    onChange={handleInputChange}
                    placeholder="Enter your email address."
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        border: darkMode
                          ? "1px solid #fff"
                          : "1px solid #151515",
                        fontSize: "13px",
                        color: darkMode ? "#fff" : "#151515",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <InputLabel
                    className={darkMode ? "dark" : "light"}
                    sx={{ fontSize: "12px", mb: 0.5, fontWeight: 500 }}
                  >
                    Phone Number
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Enter your phone number"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        border: darkMode
                          ? "1px solid #fff"
                          : "1px solid #151515",
                        fontSize: "13px",
                        color: darkMode ? "#fff" : "#151515",
                      },
                    }}
                  />
                </Box>
              </Box>
              <>
                <Box
                  sx={{
                    borderTop: darkMode
                      ? "5px solid #151515"
                      : "5px solid #dedede",
                    mt: 5,
                    px: 3,
                    pt: 4,
                    height: "400px",
                  }}
                >
                  <Typography
                    className={darkMode ? "dark" : "light"}
                    sx={{ fontSize: "14px", fontWeight: 700 }}
                  >
                    Payment Option
                  </Typography>
                  <Typography
                    sx={{
                      color: darkMode ? "#fff" : "#5b5b5b",
                      mt: 1,
                      fontSize: "13px",
                    }}
                  >
                    How do you intend to pay for the order?
                  </Typography>

                  <Box sx={{ mt: 3, width: "100%" }}>
                    <ListItemButton
                      onClick={() => setSelectedPayment("digital_payment")}
                      selected={selectedPayment === "digital_payment"}
                      sx={{
                        border: "1.5px solid #dedede",
                        height: "70px",
                        borderRadius: "12px",
                        "&:hover": {
                          bgcolor: "#75007E1a",
                          borderColor: "primary.main",
                        },
                        ...(selectedPayment === "digital_payment" && {
                          borderColor: "primary.main",
                        }),
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img
                          src={
                            selectedPayment === "digital_payment"
                              ? offlineIcon2
                              : offlineIcon
                          }
                        />
                        <Box>
                          <Typography
                            sx={{
                              color: darkMode ? "#fff" : "#151515",
                              fontWeight: 500,
                              ...(selectedPayment === "digital_payment" && {
                                color: "primary.main",
                              }),
                            }}
                          >
                            Digital Payment
                          </Typography>
                          <Typography
                            sx={{
                              color: darkMode ? "#fff" : "#5b5b5b",
                              mt: 1,
                              fontSize: "12px",
                              ...(selectedPayment === "digital_payment" && {
                                color: "primary.main",
                              }),
                            }}
                          >
                            Complete payment via bank transfer
                          </Typography>
                        </Box>
                      </Box>
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setSelectedPayment("offline_payment")}
                      selected={selectedPayment === "offline_payment"}
                      sx={{
                        border: "1.5px solid #dedede",
                        height: "70px",
                        borderRadius: "12px",
                        mt: 3,
                        "&:hover": {
                          bgcolor: "#75007E1a",
                          borderColor: "primary.main",
                        },
                        ...(selectedPayment === "offline_payment" && {
                          borderColor: "primary.main",
                        }),
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img
                          src={
                            selectedPayment === "offline_payment"
                              ? digitalIcon2
                              : digitalIcon
                          }
                        />
                        <Box>
                          <Typography
                            sx={{
                              color: darkMode ? "#fff" : "#151515",
                              fontWeight: 500,
                              ...(selectedPayment === "offline_payment" && {
                                color: "primary.main",
                              }),
                            }}
                          >
                            Offline Payment
                          </Typography>
                          <Typography
                            sx={{
                              color: darkMode ? "#fff" : "#5b5b5b",
                              mt: 1,
                              fontSize: "12px",
                              ...(selectedPayment === "offline_payment" && {
                                color: "primary.main",
                              }),
                            }}
                          >
                            Complete payment with Cash or POS
                          </Typography>
                        </Box>
                      </Box>
                    </ListItemButton>
                  </Box>
                </Box>
              </>

              <Paper
                sx={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: { lg: "30%", md: "40%", sm: "50%", xs: "100%" },
                  margin: "0 auto",
                  height: "100px",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: 0,
                  background: darkMode ? "#000" : "#fff",
                }}
              >
                {/* <Link to={`/reservation/${uid}`}> */}
                <Button
                  onClick={() => setActiveStep(1)}
                  disabled={
                    !fullName ||
                    !email ||
                    !phoneNumber ||
                    isLoading ||
                    error ||
                    (walkin && !selectedPayment)
                  }
                  sx={{
                    width: "300px",
                    height: "63px",
                    background: darkMode ? "#fff" : "#000",
                    color: darkMode ? "#151515" : "#fff",
                    textTransform: "initial",
                    fontWeight: 700,
                    "&:hover": {
                      backgroundColor: darkMode ? "#ffffffb7" : "#151515b7",
                    },
                    "&:disabled": {
                      color: "#fff",
                      bgcolor: "#5b5b5b",
                      cursor: "not-allowed",
                    },
                  }}
                >
                  Continue
                </Button>
                {/* </Link> */}
              </Paper>
            </>
          ) : (
            <>
              <Box
                sx={{
                  borderTop: darkMode
                    ? "5px solid #151515"
                    : "5px solid #dedede",
                  mt: 5,
                  px: 3,
                  pt: 4,
                }}
              >
                <Typography
                  className={darkMode ? "dark" : "light"}
                  sx={{ fontSize: "14px", fontWeight: 700 }}
                >
                  Timing Details{" "}
                </Typography>

                <Typography
                  sx={{
                    color: darkMode ? "#fff" : "#5b5b5b",
                    mt: 1,
                    fontSize: "13px",
                  }}
                >
                  What Day & Time are you reserving for?
                </Typography>

                <Box
                  className="hide_scrollbar"
                  sx={{
                    mt: 2,
                    overflow: "scroll",
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                  }}
                >
                  {nextSevenDays?.map((date, index) => {
                    const isOpen = data?.opening_hours?.some((openingHour) =>
                      openingHour.day
                        .toLowerCase()
                        .includes(date?.day.toLowerCase())
                    );
                    return (
                      <Box
                        key={index}
                        onClick={() => isOpen && setSelectedDate(date)}
                        sx={{
                          height: "93px",
                          width: "93px",
                          borderRadius: "16px",
                          border: "1px solid #f4f4f4",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          boxSizing: "border-box",
                          px: 4,
                          cursor: "pointer",
                          transition: "0.2s  all linear",

                          ...(!isOpen && {
                            opacity: 0.3,
                            cursor: "not-allowed",
                            background: darkMode ? "#151515" : "#f4f4f4",
                          }),
                          ...(isOpen && {
                            "&:hover": {
                              border: darkMode
                                ? "1px solid #F489FD"
                                : "1px solid #75007E",
                            },
                          }),

                          ...(selectDate === date && {
                            bgcolor: darkMode ? "#000" : "#FCEDFE",
                            border: darkMode
                              ? "1px solid #F489FD"
                              : "1px solid #75007E",
                          }),
                        }}
                      >
                        {!isOpen && (
                          <Box
                            sx={{
                              fontSize: "9px",
                              color: darkMode ? "#EA8072" : "#A71200",
                            }}
                          >
                            Closed
                          </Box>
                        )}
                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: 400,
                            color: darkMode ? "#fff" : "#5b5b5b",
                          }}
                        >
                          {index === 0
                            ? "Today"
                            : index === 1
                            ? "Tomorrow"
                            : date?.day}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 400,
                            color: darkMode ? "#fff" : "#151515",
                            mt: 1,
                            ...(selectDate === date && {
                              color: darkMode ? "#F489FD" : "#75007E",
                            }),
                          }}
                        >
                          {date?.month}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 400,
                            color: darkMode ? "#fff" : "#151515",
                            mt: 0.3,
                            ...(selectDate === date && {
                              color: darkMode ? "#F489FD" : "#75007E",
                            }),
                          }}
                        >
                          {date?.date}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>

                <Box sx={{ mt: 3 }}>
                  {upcomingTimes?.length === 0 &&
                  matchingDay === selectedOpeningHour ? (
                    <></>
                  ) : (
                    <>
                      <Typography
                        sx={{
                          color: darkMode ? "#fff " : "#5b5b5b",
                          fontSize: "13px",
                        }}
                      >
                        This spot opens between{" "}
                        <b style={{ color: darkMode ? "#fff" : "#151515" }}>
                          {" "}
                          {convertTo12HourFormat(
                            selectedOpeningHour?.open_time
                          )}{" "}
                          &{" "}
                          {convertTo12HourFormat(
                            selectedOpeningHour?.close_time
                          )}
                        </b>
                      </Typography>
                    </>
                  )}

                  <Box
                    className="hide_scrollbar"
                    sx={{
                      mt: 2,
                      overflow: "scroll",
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                    }}
                  >
                    {upcomingTimes?.length === 0 &&
                      matchingDay === selectedOpeningHour && (
                        <Typography sx={{ color: "#EA8072" }}>
                          Closed for today, try booking ahead
                        </Typography>
                      )}
                    {mappedTime?.map((time, index) => (
                      <Box
                        key={index}
                        onClick={() => setSelectedTime(time)}
                        sx={{
                          border: "1px solid #f4f4f4",
                          px: 3,
                          height: "66px",
                          display: "grid",
                          placeItems: "center",
                          borderRadius: "16px",
                          fontSize: "14px",
                          cursor: "pointer",
                          color: darkMode ? "#fff" : "#151515",
                          transition: "0.2s  all linear",
                          "&:hover": {
                            border: darkMode
                              ? "1px solid #F489FD"
                              : "1px solid #75007E",
                          },
                          ...(selectTime === time && {
                            bgcolor: darkMode ? "#000" : "#FCEDFE",
                            border: darkMode
                              ? "1px solid #F489FD"
                              : "1px solid #75007E",
                            color: darkMode ? "#F489FD" : "#75007E",
                          }),
                        }}
                      >
                        {time?.time12Hour}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  borderTop: darkMode
                    ? "5px solid #151515"
                    : "5px solid #dedede",
                  mt: 5,
                  px: 3,
                  pt: 4,
                }}
              >
                <Typography
                  className={darkMode ? "dark" : "light"}
                  sx={{ fontSize: "14px", fontWeight: 700 }}
                >
                  Contact Details
                </Typography>

                <Typography
                  sx={{
                    color: darkMode ? "#fff" : "#5b5b5b",
                    mt: 1,
                    fontSize: "13px",
                  }}
                >
                  Please provide the following information for your reservation.
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <InputLabel
                    className={darkMode ? "dark" : "light"}
                    sx={{ fontSize: "12px", mb: 0.5, fontWeight: 500 }}
                  >
                    Full Name
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    placeholder="Enter your full name"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        border: darkMode
                          ? "1px solid #fff"
                          : "1px solid #151515",
                        color: darkMode ? "#fff" : "#151515",
                        fontSize: "13px",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <InputLabel
                    className={darkMode ? "dark" : "light"}
                    sx={{ fontSize: "12px", mb: 0.5, fontWeight: 500 }}
                  >
                    Email Address
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={email}
                    error={error}
                    helperText={error ? "Not a valid email" : ""}
                    onChange={handleInputChange}
                    placeholder="Enter your email address."
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        border: darkMode
                          ? "1px solid #fff"
                          : "1px solid #151515",
                        fontSize: "13px",
                        color: darkMode ? "#fff" : "#151515",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <InputLabel
                    className={darkMode ? "dark" : "light"}
                    sx={{ fontSize: "12px", mb: 0.5, fontWeight: 500 }}
                  >
                    Phone Number
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Enter your phone number"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        border: darkMode
                          ? "1px solid #fff"
                          : "1px solid #151515",
                        fontSize: "13px",
                        color: darkMode ? "#fff" : "#151515",
                      },
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  borderTop: darkMode
                    ? "5px solid #151515"
                    : "5px solid #dedede",
                  mt: 5,
                  px: 3,
                  pt: 4,
                }}
              >
                <Typography
                  className={darkMode ? "dark" : "light"}
                  sx={{ fontSize: "14px", fontWeight: 700 }}
                >
                  Guest Size
                </Typography>

                <Typography
                  sx={{
                    color: darkMode ? "#fff" : "#5b5b5b",
                    mt: 1,
                    fontSize: "13px",
                  }}
                >
                  How many people are you reserving for. Your menu lets you add{" "}
                  <b style={{ color: darkMode ? "#fff" : "#151515" }}>
                    {isLoading ? (
                      <LinearProgress sx={{ width: "20px", height: "10px" }} />
                    ) : (
                      guestLimit
                    )}{" "}
                    additional Guest.
                  </b>
                </Typography>

                <Box sx={{ mt: 2, height: "300px" }}>
                  <TextField
                    fullWidth
                    value={guestSize}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        border: darkMode
                          ? "1px solid #fff"
                          : "1px solid #151515",
                        fontSize: "13px",
                        fontWeight: 600,
                        color: darkMode ? "#fff" : "#151515",
                      },
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: -1.6 }}>
                          <IconButton
                            onClick={() => {
                              guestSize >= 2 &&
                                setGuestSize((prev) => prev - 1);
                            }}
                          >
                            <img src={minus2} />
                          </IconButton>{" "}
                          <IconButton
                            onClick={() => {
                              if (guestSize < guestLimit) {
                                setGuestSize((prev) => prev + 1);
                              }
                            }}
                          >
                            <img src={plus2} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>

              <Paper
                sx={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: { lg: "30%", md: "40%", sm: "50%", xs: "100%" },
                  margin: "0 auto",
                  height: "100px",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: 0,
                  background: darkMode ? "#000" : "#fff",
                }}
              >
                {/* <Link to={`/reservation/${uid}`}> */}
                <Button
                  onClick={() => setActiveStep(2)}
                  disabled={
                    !fullName ||
                    !email ||
                    !phoneNumber ||
                    !selectDate ||
                    !selectTime ||
                    isLoading ||
                    error
                  }
                  sx={{
                    width: "300px",
                    height: "63px",
                    background: darkMode ? "#fff" : "#000",
                    color: darkMode ? "#151515" : "#fff",
                    textTransform: "initial",
                    fontWeight: 700,
                    "&:hover": {
                      backgroundColor: darkMode ? "#ffffffb7" : "#151515b7",
                    },
                    "&:disabled": {
                      color: "#fff",
                      bgcolor: "#5b5b5b",
                      cursor: "not-allowed",
                    },
                  }}
                >
                  Continue
                </Button>
                {/* </Link> */}
              </Paper>
            </>
          )}
        </>
      )}

      {activeStep === 1 && (
        <>
          <OverviewWalkin
            selectedPayment={selectedPayment}
            uid={uid}
            source={source}
            data={data}
            darkMode={darkMode}
            selectedMenu={selectedMenuList}
            details={{
              fullName,
              email,
              phoneNumber,
              selectDate,
              selectTime,
              menuList,
              guestSize,
            }}
            handleBack={() => setActiveStep(0)}
            totalBill={
              calculateTotalUnitPrice(menuList) + 1000 + added_tax + consume_tax
            }
          />
        </>
      )}
      {activeStep === 2 && (
        <>
          <OverviewOnline
            selectedPayment={selectedPayment}
            uid={uid}
            source={source}
            data={data}
            darkMode={darkMode}
            selectedMenu={selectedMenuList}
            details={{
              fullName,
              email,
              phoneNumber,
              selectDate,
              selectTime,
              menuList,
              guestSize,
            }}
            handleBack={() => setActiveStep(0)}
            totalBill={
              calculateTotalUnitPrice(menuList) + 1000 + added_tax + consume_tax
            }
          />
        </>
      )}
    </>
  );
};

export default Information;

const convertTo12HourFormat = (timeString) => {
  // Parse the time string into a Date object
  const time = new Date(`2000-01-01T${timeString}`);

  // Format the time in 12-hour format
  const formattedTime = time.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return formattedTime;
};

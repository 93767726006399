import React, { useState, useEffect } from "react";
import Container from "../../Reservation/Container";
import { Box, LinearProgress, Typography, Skeleton , InputLabel, IconButton, Paper} from "@mui/material";
import { useParams, useNavigate, Link } from "react-router-dom";
import { checkTicketPayment, getRestaurantDetails } from "../../assets/img/api";
import successImg from "../../assets/img/vectors/ticket_buy.svg";
import Icons from "../../constant/Icons";
import failedImg from '../../Reservation/Assets/img/security _ protection, safety, umbrella, shield, alert, message, antivirus, notification, malware.svg'

const EventCheck = () => {
  const [isLoading, setIsLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const walkinParam = urlParams.get("walkin");
  const reservation_id = urlParams.get("reservation_id");
  const emailParam = urlParams.get("email");
  const transaction_reference = urlParams.get("transaction_reference");
  const uid = urlParams.get("uid");
  console.log(uid);
  const [status, setStatus] = useState({
    success: true,
    failed: false,
    pending: false,
    overpaid: false,
    underpaid: false,
  });

  const { success, failed, pending, overpaid, underpaid } = status;

  const [data, setData] = useState([]);
  const [event, setEvent] = useState(null)

  const handleGetRestaurantDetails = async () => {
    await getRestaurantDetails(uid)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleGetRestaurantDetails();
  }, []);
  const [reciept, setReciept] = useState(null)
  const handleCheckPayment = async () => {
    setIsLoading(true);

    await checkTicketPayment(transaction_reference, emailParam)
      .then((res) => {
        const { payment_status, reservation_status, receipt } = res?.data?.data;
        setReciept(receipt)
        console.log(payment_status, reservation_status);

        if (
          payment_status === "pending" ||
          reservation_status === "payment_pending"
        ) {
          setStatus({
            ...status,
            pending: true,
            success: false,
            failed: false,
            overpaid: false,
            underpaid: false,
          });
        }
        if (payment_status === "fully_paid") {
          setStatus({
            ...status,
            pending: false,
            success: true,
            failed: false,
            overpaid: false,
            underpaid: false,
          });
        }
        if (payment_status === "over_paid") {
          setStatus({
            ...status,
            pending: false,
            success: false,
            failed: false,
            overpaid: true,
            underpaid: false,
          });
        }
        if (payment_status === "under_paid") {
          setStatus({
            ...status,
            pending: false,
            success: false,
            failed: false,
            overpaid: false,
            underpaid: true,
          });
        }
        if (payment_status === "failed") {
          setStatus({
            ...status,
            pending: false,
            success: false,
        
            overpaid: false,
            underpaid: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setStatus({
          ...status,
          pending: false,
          success: false,
       
          overpaid: false,
          underpaid: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleCheckPayment();
  }, []);

  return (
    <>
      <Container>
        {isLoading ? (
          <>
            <Box
              sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                Confirming ticket payment...
              </Typography>
              <LinearProgress sx={{ width: "40%", mt: 3 }} />
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                height: "100vh",
                background: "#fff",
                px: 3,
              }}
            >
              <Box sx={{ pt: 10, display: "grid", placeItems: "center" }}>
                {isLoading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{ width: "80px", height: "80px" }}
                  />
                ) : (
                  <a
                    href={`https://www.vibezsup.com/${data?.online_reservation_link}`}
                  >
                    <img src={data?.logo} width={70} />
                  </a>
                )}
              </Box>
              <Box
                sx={{
                  height: "60vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 3,
                }}
              >
                {success && (
                  <>
                    <img src={successImg} />
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontFamily: "butler",
                        fontWeight: 700,
                        mt: 2,
                      }}
                    >
                      Ticket Successfully Purchased
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        color: "#5b5b5b",
                        mt: 1,
                      }}
                    >
                      An email containing your receipt and ticket has been
                      <br /> sent to your email.
                    </Typography>
                    <Box
                      sx={{
                        mt: 4,
                        width: "300px",
                        border: "1px solid #dedede",
                        borderRadius: "12px",
                        bgcolor: "#fff",
                        p: 1,
                        boxSizing: "border-box",
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          background: `url(${reciept?.banner})`,
                          height: "100px",
                          width: "30%",
                          backgroundSize: "cover",
                          borderRadius: "10px",
                        }}
                      />
                      <Box sx={{ width: "70%" }}>
                        <InputLabel sx={{ fontWeight: 700, color: "#000" }}>
                          {reciept?.name}
                        </InputLabel>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 1,
                            mt: 1,
                          }}
                        >
                          <img src={Icons.location2} />
                          <InputLabel
                            sx={{ color: "primary.main", fontSize: "12px" }}
                          >
                            {reciept?.location}
                          </InputLabel>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 1,
                            mt: 1,
                          }}
                        >
                          <img src={Icons.calendar} />
                          <InputLabel
                            sx={{ color: "#8f8f8f", fontSize: "12px" }}
                          >
                            {reciept?.date}
                          </InputLabel>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}

                {/* OVERPAID ======================================= */}
                {overpaid && (
          <>
            <img src={successImg} width={150} />
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "butler",
                fontWeight: 700,
                mt: 2,
              }}
            >
              Ticket Successfully Purchased
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "12px",
                color: "#5b5b5b",
                mt: 1,
              }}
            >
              An email containing your receipt and ticket has been
              <br /> sent to your email.
            </Typography>
            <Box
              sx={{
                mt: 3,
                border: "0.5px solid  #C56000",
                bgcolor: "#FFF2E5",
                color: "#C56000",
                borderRadius: "12px",
                p: 2,
                boxSizing: "border-box",
                fontSize: "12px",
                textAlign: "center",
                fontWeight: 400,
                width: "300px",
              }}
            >
              We observed an overpayment in the amount received from your
              payment. Please reach out to the host to assist with reversal of
              the excess funds.
            </Box>
          </>
        )}
                {underpaid && <>
                   <img src={failedImg} width={150} />
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "butler",
                fontWeight: 700,
                mt: 2,
              }}
            >
         Ticket Purchase Failed
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "12px",
                color: "#5b5b5b",
                mt: 1,
              }}
            >
    The amount received was short of the total reservation fee. Kindly reach out to the host to process a total refund or balance the shortfall.
            </Typography>
                
                </>}
              </Box>
            </Box>
          </>
        )}
                <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            width: { lg: "30%", md: "40%", sm: "50%", xs: "100%" },
            margin: "0 auto",
            height: "70px",
            display: "grid",
            placeItems: "center",
            borderRadius: 0,
            background: "#fff",
            borderTop: "none",
            boxShadow: "none",
          }}
        >
          <Link to="/">
            <Typography
              color="primary"
              sx={{
                fontWeight: 500,
                fontSize: "10px",
                bgcolor: "#FCEDFE",
                width: "120px",
                height: "26px",
                borderRadius: "50px",
                display: "grid",
                placeContent: "center",
              }}
            >
              Powered by VibezsUp
            </Typography>
          </Link>
        </Paper>
      </Container>
    </>
  );
};

export default EventCheck;

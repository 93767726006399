import React, { useState, useEffect } from "react";
import Navbar from "../Components/Common/Navbar";
import { Box, Button, Typography, Popover, MenuItem, Grid } from "@mui/material";
import BlogImg from "../assets/img/vectors/Blog.svg";
import vblogImg from "../assets/img/vectors/VibezsBlog.svg";
import { IoIosArrowDown } from "react-icons/io";
import BlogCard from "../Components/Blog/BlogCard";
import line from "../assets/img/vectors/line.svg";
import Footer from "../Components/Common/Footer";
import { Link } from "react-router-dom";
import Client from "../Components/Blog/Client";

const Blog = ({}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
      const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("All Posts");
  const [filteredPost, setFilteredPost] = useState([])

  useEffect(()=>{
    setFilteredPost(blogs)
  }, [blogs])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;




  useEffect(() => {
    setIsLoading(true)
    Client.fetch(
      `*[_type == "event"] {
    name,
    slug, blogType,date, description,image,details, readDuration}
`
    )
      .then((data) =>{
 setBlogs(data);
 setIsLoading(false)
      })
      .catch((err)=>{
        console.log(err);
        setIsLoading(false)
      });
  }, []);

useEffect(()=>{
  if(filter === "All Posts"){
    setFilteredPost(blogs)
  }else{
const filtered = blogs?.filter((item) => item?.blogType === filter)

setFilteredPost(filtered)
  }

}, [filter])

  return (
    <>
      <Navbar />

      <Box sx={{ pt: 12 }}>
        <Box
          sx={{
            backgroundImage: `url('${BlogImg}')`,
            backgroundSize: "cover",
            height: "250px",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={vblogImg} width={300} />
          <Typography
            sx={{
              textAlign: "center",
              color: "#5b5b5b",
              width: {md:"30%", sm:'70%', xs:'90%'},
              mt: 1,
              fontWeight: 400,
              fontSize:{md:'14px', sm:'12px' , xs:'12px'}
            }}
          >
            Catch up on all the Vibezs Update in your City and the best plugs on
            how to Chooooop life 😉
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: { xl: "70%", md: "80%", xs: "90%" },
          margin: "0 auto",
          mt: 4,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
          <Button
            onClick={handleClick}
            aria-describedby={id}
            endIcon={<IoIosArrowDown style={{ fontSize: "12px" }} />}
            sx={{
              color: "#5b5b5b",
              fontSize: "12px",
              textTransform: "capitalize",
              border: "1px solid #dedede",
              transition:'0.2s all linear',
              px:2
            }}
          >
            Showing : <span style={{ color: "#151515" ,     transition:'0.2s all linear'}}> {filter}</span>
          </Button>
          <Typography sx={{ color: "#5b5b5b", fontSize: "12px" }}>
            {filteredPost?.length} posts
          </Typography>
        </Box>
        <Box sx={{mt:3}}>
          <Grid container spacing={4}>
            {
             filteredPost?.map((blog, index)=>(
                <>
                <Grid  item md={3}  sm={6}  xs={12} key={index}>
                   <Link to={`/blog/${blog?.slug?.
current}`}>
                  <BlogCard data={blog} loading={isLoading}/>
                  </Link>
                </Grid>
                </>
              ))
            }
          </Grid>
        </Box>
        <Box
        sx={{
          width: "70%",
          margin: "0 auto",
          background: `url('${line}')`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          py: { md: 8, xs: 0 },
        }}
      />

      </Box>
      <Footer/>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 0, width: "130px" }}>
          <MenuItem
            onClick={() => {
              setFilter("All Posts");
              handleClose()
            }}
            selected={filter === "All Posts"}
            sx={{
              fontSize: "12px",
              color: "#151515",
              "&.Mui-selected": { color: "#5b5b5b" },
            }}
          >
            All Posts
          </MenuItem>
          <MenuItem
            onClick={() => {
              setFilter("For Diner")
              handleClose()
            }}
            selected={filter === "For Diner"}
            sx={{
              fontSize: "12px",
              color: "#151515",
              "&.Mui-selected": { color: "#5b5b5b" },
            }}
          >
            For Diner
          </MenuItem>
          <MenuItem
            onClick={() => {
              setFilter("For Restaurant")
              handleClose()
            }}
            selected={filter === "For Restaurant"}
            sx={{
              fontSize: "12px",
              color: "#151515",
              "&.Mui-selected": { color: "#5b5b5b", },
            }}
          >
            For Restaurant
          </MenuItem>
        </Box>
      </Popover>
    </>
  );
};

export default Blog;

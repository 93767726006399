import React, {useState, useEffect} from "react";
import Navbar from "../Common/Navbar";
import Footer from "../Common/Footer";
import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import BlogCard from "./BlogCard";
import Client from "./Client";
import BlockContent from "@sanity/block-content-to-react"
import { parseISO, format } from 'date-fns';
import { urlFor } from "./sanityImageClient";


const SingleBlog = () => {
  const [blogs, setBlogs] = useState([]);
   const [singlePost, setSinglePost] = useState(null)
     const [isLoading, setIsLoading] = useState(false)
   const { slug } = useParams();

   useEffect(() => {
  Client.fetch(`*[slug.current == "${slug}"] {
name,
    slug, blogType,date, description,image,details, readDuration
}`).then((data) => setSinglePost(data[0])).catch(console.error)
}, [slug])

  useEffect(() => {
    setIsLoading(true)
    Client.fetch(
      `*[_type == "event"] {
    name,
    slug, blogType,date, description,image,details, readDuration}
`
    )
      .then((data) =>{
 setBlogs(data);
 setIsLoading(false)
      })
      .catch((err)=>{
        console.log(err);
        setIsLoading(false)
      });
  }, [slug]);

  const [filteredPosts,  setFilteredPosts] = useState(null)

  useEffect(()=>{
const filtered =  blogs?.filter((item) => item?.slug?.current !== slug);
setFilteredPosts(filtered)
  },[slug, blogs])


  return (
    <>
      <Navbar />
  {
    (!singlePost ||  singlePost?.length === 0) ? (
      <>
<Box sx={{display:'grid', placeItems:'center', height:'70vh'}}>
  <LinearProgress sx={{width:'200px'}} />
</Box>
      </>
    ):(
          <Box sx={{ pt: 12 }}>
        <Box sx={{ margin: "0 auto", width: { lg: "50%", md:'60%' , sm:'90%', xs:'90%'}, pt: 3 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 3,
              justifyContent: "center",
            }}
          >
                 {
              singlePost && (
                <>
                {
                  singlePost?.
blogType === "For Diner" ? (
      <Typography
              sx={{
                bgcolor: "#FFF2E5",
                fontSize: "12px",
                color: "#C56000",
                px: 0.8,
                borderRadius: "4px",
              }}
            >
              For Diners
            </Typography>
):(
      <Typography
              sx={{
                bgcolor: "#E8FFE8",
                fontSize: "12px",
                color: "#007E23",
                px: 0.8,
                borderRadius: "4px",
              }}
            >
             {singlePost && singlePost?.blogType}
            </Typography>
)
                }
                </>
              )
            }
            <Typography sx={{ color: "#5b5b5b", fontSize: "12px" }}>
               {singlePost && formatDate(singlePost?.date)}
            </Typography>
          </Box>

          <Typography
            sx={{
              fontWeight: 700,
              fontSize:{lg: "30px",  md:'30px', sm:'25px', xs:'25px'},
              textAlign: "center",
              lineHeight: "40px",
              mt: 3,
            }}
          >
           {singlePost?.name} 
          </Typography>

          <Typography sx={{ fontSize: "12px", textAlign: "center", mt: 1 }}>
         {singlePost?.description}
          </Typography>

          <Box
            sx={{
              height: "300px",

              borderRadius: "20px",
              my: 4,
                   background: `url('${singlePost && urlFor(singlePost?.image?.asset?._ref).url()}')`,
                                backgroundSize:'cover',
             backgroundPosition:'center',
            }}
          />

          <Box className="custom-block-content" sx={{mt:3, fontSize:{md:'14px', sm:'12px', xs:'12px'}}}>
             <BlockContent
      blocks={singlePost?.details}
      projectId="ei6dtsjr"
      dataset="production"
    />
          </Box>

          <Box>
            <Box
              sx={{
                mt: 8,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{fontWeight:700}}>More Blogposts</Typography>
              <Link to="/blogs">
              <Button sx={{fontSize:'12px', textTransform:'initial', border:'1px solid  #dedede', borderRadius:'20px', px
                :3, color:'#C56000'
              }}>View all posts</Button></Link>
            </Box>
            <Box sx={{mt:4, mb:7}}>
              <Grid container  spacing={2}>
                {
                  filteredPosts?.slice(0,3)?.map((blog, index)=>(
   <Grid item lg={4} md={4} sm={4} xs={12}>
 <Link to={`/blog/${blog?.slug?.current}`}>
    <BlogCard data={blog}/>
    </Link>

                </Grid>
                  ))
                }
             
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
      <Footer />
    </>
  );
};

export default SingleBlog;

  const formatDate = (dateString, dateFormat = 'MMMM d, yyyy') => {
  const date = parseISO(dateString);
  return format(date, dateFormat);
};

import React, { useEffect, useState } from "react";
import Container from "../Container";
import { getRestaurantDetails } from "../../assets/img/api";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  IconButton,
  Typography,
  Paper,
  Button,
  LinearProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import backIcon from "../Assets/img/back.svg";
import minus from "../Assets/img/minus.svg";
import plus from "../Assets/img/plus.svg";
import Information from "./Information";
import { RiSearch2Line } from "react-icons/ri";
import { GoArrowLeft } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import emptySvg from '../Assets/img/empty.svg'

const Menu = () => {
  const { uid, name } = useParams();
  const navigate = useNavigate();
   const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [menuData, setMenuData] = useState(null);
  const [filter, setFilter] = useState(null);
  const [label, setLabel] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const [selectedMenuList, setSelectedMenuList] = useState([]);
  const [quantity, setQuantity] = useState({});
  const [activeStep, setActiveStep] = useState(1);
  const [selectedItem, setSelectedItem] = useState([]);
  const [source, setSource] = useState(null);
  const [walkin, setWalkin] = useState(false)

    useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get('source');
    const walkin = queryParams.get('walkin');
setSource(source);
setWalkin(walkin)
  }, [location.search]);



  const [data, setData] = useState([]);

  const handleGetRestaurantDetails = async () => {
    setIsLoading(true);
    await getRestaurantDetails(uid)
      .then((res) => {
        setIsLoading(false);
          setDarkMode(res?.data?.data?.dark_mode);
        setData(res?.data?.data);
        setLabel(res?.data?.data?.tables);
        setMenuData(res?.data?.data?.tables);
        if (res?.data?.status === false) {
          navigate("/not-found");
        }
      })
      .catch((err) => {
        setIsLoading(false);


        navigate("/not-found");
      });
  };

  useEffect(() => {
    handleGetRestaurantDetails();
  }, []);

const addToCart = (menuId, menuItem) => {
  const itemIndex = selectedMenuList.findIndex(
    (item) => item.menu_id === menuId
  );

  if (itemIndex === -1) {
    setSelectedMenuList([
      ...selectedMenuList,
      { menu_id: menuId, quantity: 1 },
    ]);
    setQuantity((prevQuantity) => ({
      ...prevQuantity,
      [menuId]: 1,
    }));
  } else {
    const updatedMenuList = [...selectedMenuList];
    updatedMenuList[itemIndex].quantity++;
    if (updatedMenuList[itemIndex].quantity < 1) {
      updatedMenuList[itemIndex].quantity = 1; // Ensure minimum quantity is 1
    }
    setSelectedMenuList(updatedMenuList);
    setQuantity((prevQuantity) => ({
      ...prevQuantity,
      [menuId]: (prevQuantity[menuId] || 0) + 1,
    }));
  }
  setSelectedItem([
    ...selectedItem,
    { ...menuItem, menu_id: menuId, quantity: quantity[menuId] || 1 },
  ]);
};

  const increaseQuantity = (menuId) => {
    setQuantity((prevQuantity) => ({
      ...prevQuantity,
      [menuId]: (prevQuantity[menuId] || 0) + 1,
    }));

    setSelectedMenuList((prevList) => {
      const updatedList = prevList.map((item) => {
        if (item.menu_id === menuId) {
          return { ...item, quantity: (item.quantity || 0) + 1 };
        }
        return item;
      });
      return updatedList;
    });

    setSelectedItem((prevItems) => {
      const updatedItems = prevItems.map((item) => {
        if (item.menu_id === menuId) {
          return { ...item, quantity: (item.quantity || 0) + 1 };
        }
        return item;
      });
      return updatedItems;
    });


  };

  // Function to handle decreasing quantity
  const decreaseQuantity = (menuId) => {
    if (quantity[menuId] <= 1){
removeItem(menuId)
    }

    if (quantity[menuId] > 1) {
      setQuantity((prevQuantity) => ({
        ...prevQuantity,
        [menuId]: prevQuantity[menuId] - 1,
      }));
    }

      setSelectedMenuList((prevList) => {
        const updatedList = prevList.map((item) => {
          if (item.menu_id === menuId) {
            return { ...item, quantity: (item.quantity || 0) - 1 };
          }
          return item;
        });
        return updatedList;
      });

      // Update selectedNewItems state
      setSelectedItem((prevItems) => {
        const updatedItems = prevItems.map((item) => {
          if (item.menu_id === menuId) {
            return { ...item, quantity: (item.quantity || 0) - 1 };
          }
          return item;
        });
        return updatedItems;
      });

  
  };

  const removeItem = (menuId) => {
    // Remove item from selectedMenuList
    const updatedMenuList = selectedMenuList.filter(
      (item) => item.menu_id !== menuId
    );
    setSelectedMenuList(updatedMenuList);

    // Remove item from selectedNewItems
    const updatedNewItems = selectedItem.filter(
      (item) => item.menu_id !== menuId
    );
    setSelectedItem(updatedNewItems);
       setQuantity((prevQuantity) => ({
        ...prevQuantity,
        [menuId]: 0,
      }));

      setSelectedMenuList((prevList) => {
        const updatedList = prevList.map((item) => {
          if (item.menu_id === menuId) {
            return { ...item, quantity:0 };
          }
          return item;
        });
        return updatedList;
      });


    
  };




    const handleFilter = () => {
    const filteredData = label?.filter((obj) => obj?.name === filter);
    setMenuData(filteredData);
  };

  useEffect(() => {
    handleFilter();
  }, [filter]);

  const calculateTotalUnitPrice = (data) => {
  let totalPrice = 0;
  data.forEach(item => {
    totalPrice += item.quantity * item.unit_price;
  });
  return totalPrice;
};




const [isSearch, setIsSearch ] = useState(false)

const [searchParams , setSearchParams] = useState("");
const [filteredMenu, setFilteredMenu] = useState([])

  const handleSearch = (e) => {
    setSearchParams(e.target.value.toLowerCase());
  };

useEffect(()=>{
setFilteredMenu(menuData)
}, [menuData])


useEffect(()=>{
 if(searchParams){
     const filteredData = menuData
    .map(item => ({
      ...item,
      menu_Item: item.menu_Item.filter(menuItem => 
        menuItem.name.toLowerCase().includes(searchParams)
      )
    }))
    .filter(item => item.menu_Item.length > 0);

setFilteredMenu(filteredData)
 }else{
  setFilteredMenu(menuData)
 }
}, [searchParams])


  return (
    <>
      <Container>
            {
                isLoading && <LinearProgress />
              }
        <Box
          className="hide_scrollbar"
          sx={{ bgcolor: darkMode ? "#000" :  "#fff", height: "100vh", overflow: "scroll" }}
        >
          {activeStep === 1 && (
            <>
              <Box
                sx={{
                  bgcolor: darkMode ?  "#000" :"#fff",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  width: { lg: "30%", md: "40%", sm: "50%", xs: "100%" },
                  margin: "0 auto",
                  height: "198px",
                  zIndex:1000
                }}
              >
                <Box sx={{ pt: 5, px: 3 }}>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <Box
            
                    sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                  >
                    <Link to={`/${name}/${uid}`}>
                      <IconButton>
                        <img src={backIcon} />
                      </IconButton>
                    </Link>
                    <Typography
                      sx={{
                        color: darkMode  ? "#fff" : "#151515",
                        fontSize: "16px",
                        fontWeight: 700,
                      }}
                    >
                      Our Menu
                    </Typography>
                  </Box>
                  <IconButton onClick={()=>setIsSearch(!isSearch)}>
                    <RiSearch2Line style={{color:'#75007E'}} />
                  </IconButton>
          </Box>
                  <Typography sx={{ mt: 2, color:  darkMode ? "#fff" : "#5b5b5b" }}>{isSearch ? "Search through the menu list" : "                    Build your own table by selecting from the menu below"}

                  </Typography>
            {
              isSearch ? (
                <>
                <Box sx={{display:'flex', alignItems:'center', columnGap:2, mt:2}}>
                  <IconButton onClick={()=>setIsSearch(false)} sx={{borderRadius:'8px', border:'1px solid #dedede', width:'50px', height:'50px'}}>
                    <GoArrowLeft />
                  </IconButton>
                <TextField
                fullWidth
                value={searchParams}
                onChange={handleSearch}
                placeholder="Search"
                InputProps={{
                  style:{
                    borderRadius:'12px'
                  },
                  endAdornment:(<InputAdornment position="end" onClick={()=>setSearchParams("")}><IoClose /></InputAdornment>)
                }}
                />
                </Box>
                </>
              ):(
                      <Box
                    className="hide_scrollbar"
                    sx={{
                      mt: 3,
                      display: "flex",
                      columnGap: 2,
                      overflow: "scroll",
                      transition: "0.2s all linear",
                    }}
                  >
                    <Box
                      onClick={()=>setMenuData(label)}
                      sx={{
                        height: "28px",
                        border: "1px solid #8f8f8f",
                        width: "fit-content",
                        px: 3,
                        py: 0.3,
                        display: "grid",
                        placeItems: "center",
                        fontSize: "12px",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontWeight: 500,
                        transition: "0.2s all linear",
                        color: darkMode ? "#fff" :  "#000000b7",
                        "&:hover": {
                           border:  darkMode ?  "1px solid #F489FD" : "1px solid #75007E",
                            color: darkMode ? "#F489FD" :"#75007E",
                        },
                      }}
                    >
                      All
                    </Box>
                    {label?.map((item, index) => (
                      <Box
                        key={index}
                        onClick={() => setFilter(item?.name)}
                        sx={{
                          height: "28px",
                          border: "1px solid #8f8f8f",
                          width: "fit-content",
                          px: 3,
                          py: 0.3,
                          display: "grid",
                          placeItems: "center",
                          fontSize: "12px",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontWeight: 500,
                          transition: "0.2s all linear",
                          whiteSpace:'nowrap',
                       color: darkMode ? "#fff" :  "#000000b7",
                          "&:hover": {
                            border:  darkMode ?  "1px solid #F489FD" : "1px solid #75007E",
                            color: darkMode ? "#F489FD" :"#75007E",
                          },
                          ...(filter === item?.name && {
                              border:  darkMode ?  "1px solid #F489FD" : "1px solid #75007E",
                             color: darkMode ? "#F489FD" :"#75007E",
                          }),
                        }}
                      >
                        {item?.name} 
                      </Box>
                    ))}
                  </Box>
              )
            }
                </Box>
              </Box>
          
              <Box sx={{ px: 3, bgcolor: darkMode ? "#000" :  "#fff", mt: 24, pt: 3, mb:20 }}>
                {
                  isLoading && <LinearProgress/>
                }
                {filteredMenu?.map((item, index) => (
                  <>
                    <Box key={index} sx={{ mb: 2,  }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "13px",
                          color: darkMode ?  "#fff": "#151515",
                        }}
                      >
                        {item?.name}
                      </Typography>
                      {item?.menu_Item.length === 0 ? (
                        <>
                          <Typography
                            sx={{ mt: 2, fontWeight: 600, fontSize: "13px" }}
                          >
                            No Menu Item Avalable for "{item?.name}"
                          </Typography>
                        </>
                      ) : (
                        <>
                          {item?.menu_Item?.map((item, index) => (
                            <Box
                              key={index}
                              sx={{
                                height: "100px",
                                my: 2,
                                pb: 1,
                                borderBottom: "1px solid #dedede",
                                display: "flex",
                                // alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: 2,
                                  width: "70%",
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "94px",
                                    width: "33%",
                                    borderRadius: "6px",
                                    background: `url('${item?.image}')`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    boxSizing: "border-box",
                                  }}
                                />
                                <Box sx={{ height: "94px", width: "67%" }}>
                                  <Typography
                                    className={darkMode ? "dark" : "light"}
                                    sx={{ fontWeight: 700, fontSize: "13px" }}
                                  >
                                    {item?.name}
                                  </Typography>

                                  <Typography
                                    className="two-line-ellipsis"
                                    sx={{
                                      fontSize: "12px",
                                      color: darkMode ? "#fff" : "#5b5b5b",
                                    }}
                                  >
                                    {item?.description}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      color: darkMode ? "#fff" : "#5b5b5b",
                                    }}
                                  >
                                    1 - {item?.max_guest_serving} people
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  className={darkMode ? "dark" : "light"}
                                  sx={{
                                    fontWeight: 700,
                                    fontSize: "13px",
                                    textAlign: "right",
                                  }}
                                >
                                  N{item?.unit_price.toLocaleString()}
                                </Typography>

                                {quantity[item.id] > 0 ? (
                                  <>
                                    <Box align="right">
                                      <Box
                                        sx={{
                                         border:  darkMode ?  "1px solid #F489FD" : "1px solid #75007E",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          width: "89px",
                                          borderRadius: "4px",
                                          height: "31px",
                                        }}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            decreaseQuantity(item.id)
                                          }
                                          sx={{
                                            borderRadius: 2,
                                            color: "#75007E",
                                            fontWeight: 500,
                                            py: 1,
                                            cursor:
                                              quantity === 1 && "not-allowed",
                                          }}
                                        >
                                          <img src={minus} />
                                        </IconButton>
                                        <Typography
                                          sx={{
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            color: darkMode ? "#F489FD" :  "#75007E",
                                          }}
                                        >
                                          {quantity[item.id]}
                                        </Typography>
                                        <IconButton
                                          onClick={() =>
                                            increaseQuantity(item.id)
                                          }
                                          sx={{
                                            borderRadius: 2,
                                            fontWeight: 500,
                                            color: "#75007E",
                                            py: 1,
                                            fontSize: "14px",
                                          }}
                                        >
                                          <img src={plus} />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  </>
                                ) : (
                                  <Typography
                                    onClick={() => addToCart(item.id, item)}
                                    color={darkMode ? "#F489FD" : "primary"}
                                    sx={{
                                      fontSize: "10px",
                                      textDecoration: "underline",
                                      fontWeight: 700,
                                      cursor: "pointer",
                                    }}
                                  >
                                    Tap to Add to Cart
                                  </Typography>
                                )}
                              </Box>
                              
                            </Box>
                          ))}
                        </>
                      )}
                    </Box>
                  </>
                ))}
                {
                  (!filteredMenu || filteredMenu?.length === 0) && (
                    <>
                    <Box sx={{mt:4, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                      <img src={emptySvg} />
                      <Typography sx={{mt:2, color:'primary.main'}}>
                        Searched menu not available
                      </Typography>
                    </Box>
                    </>
                  )
                }
              </Box>

              <Paper
                sx={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: { lg: "30%", md: "40%", sm: "50%", xs: "100%" },
                  margin: "0 auto",
                  height: "100px",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: 0,
                  background: darkMode ? "#000" : "#fff",
                }}
              >
                <Button
                  onClick={() => setActiveStep(2)}
                  disabled={selectedMenuList.length === 0}
                  sx={{
                    width: "300px",
                    height: "63px",
                    background: darkMode ? "#fff" : "#000",
                    color: darkMode ? "#151515" : "#fff",
                    textTransform: "initial",
                    "&:hover": { backgroundColor: darkMode  ? "#ffffffb7" : "#151515b7" },
                    "&:disabled": {
                      color: "#fff",
                      bgcolor: "#5b5b5b",
                      cursor: "not-allowed",
                    },
                  }}
                >
                  Proceed to Checkout - N{calculateTotalUnitPrice(selectedItem).toLocaleString()}
                </Button>
              </Paper>
            </>
          )}

          {activeStep === 2 && (
            <>
              <Information
              darkMode={darkMode}
              walkin={walkin}
                data={data}
                uid={uid}
            source={source}
                selectedMenuList={selectedMenuList}
                handleBack={() => setActiveStep(1)}
                menuList={selectedItem}
                increaseQuantity={increaseQuantity}
                decreaseQuantity={decreaseQuantity}
                deleteMenuItem = {removeItem}
              />
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default Menu;

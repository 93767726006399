import { Box, InputLabel, Typography } from "@mui/material";
import React from "react";
import eventImg from "../../assets/img/party.png";
import Icons from "../../constant/Icons";
import { convertTo12HourFormat } from "../../Pages/Event/Event";
import { formatDateEvent } from "../../Reservation/Pages/Reservation";

const EventCard = ({ data }) => {
  return (
    <>
      <Box
        sx={{
          border: "1px solid #DEDEDE",
          // height: "250px",
          borderRadius: "12px",
          boxSizing: "border-box",
          p: 1.5,
          // width:'200px',
          cursor: "pointer",
          transition: "0.1s all linear",
          "&:hover": {
            borderColor: "primary.main",
          },
        }}
      >
        <Box
          sx={{
            height: "130px",
            background: `url('${data?.banner}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "8px",
          }}
        />
        <Box sx={{ mt: 1 }}>
          <InputLabel sx={{ color: "#000", fontWeight: 700 }}>
            {data?.name}
          </InputLabel>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 1,
              mt: 0.5,
            }}
          >
            <img src={Icons.ticket} />
            <Typography sx={{ fontSize: "10px" }}>
              Tickets from{" "}
              <b style={{ color: "#75007E" }}>
                {" "}
                N
                {parseFloat(
                  data?.ticket_min_price || data?.ticket
                ).toLocaleString()}
              </b>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
            <img src={Icons.calendar} />
            <Typography sx={{ fontSize: "10px" }}>
              {data?.frequency === "one-off" ? (
                <>{formatDateEvent(data?.event_time)}</>
              ) : (
                <>{`Every ${data?.event_day}s`}</>
              )}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
            <img src={Icons.time} />
            <Typography sx={{ fontSize: "10px" }}>
              {" "}
              {data?.kick_off_time &&
                convertTo12HourFormat(data?.kick_off_time)}{" "}
              - {data?.close_time && convertTo12HourFormat(data?.close_time)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
            <img src={Icons.location} />
            <Typography sx={{ fontSize: "10px" }}>
              13, Philemon Close, VI, Lagos
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EventCard;

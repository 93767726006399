import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { BorderBottom, CloseOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const style = {
  position: "absolute",
  top: "5%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FFE8E5",
  BorderBottom: "2px solid  #A71200",
  boxShadow: 24,
  p: 4,
  px:3,
  boxSizing: "border-box",
};

const Alert = ({ message, open, handleClose }) => {
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{width:'80%'}}>
                <Typography
                  sx={{
                    color: "#A71200",
          
                    fontWeight: 700,
                  }}
                >
                  Error Message
                </Typography>
                <Typography
                  sx={{ pt: 0.5, color: "#A71200", }}
                >
                  {message}
                </Typography>
              </Box>
              <IconButton onClick={handleClose} sx={{width:'10%'}}>
                <CloseOutlined sx={{ color: "#A71200" }} />
              </IconButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default Alert;

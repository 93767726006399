import React from 'react'
import Container from '../Container'
import { Box , Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <>
    <Container>
           <Box
        className="not_found"
        sx={{
          height: "100vh",
          backgroundColor: "#FCEDFE",
          // backgroundImage: `url('${bg}')`,
          backgroundSize: "contain",
          backgroundPosition: "150px",
          backgroundRepeat: "no-repeat",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ color: "#000", fontSize: "80px", fontWeight: 600 }}>
          404
        </Typography>
        <Typography
          sx={{
            fontFamily: "Gavency Free",
            color: "#000",
            mt: 7,
            fontSize: "30px",
          }}
        >
Restaurant Not Found
        </Typography>
        <Typography sx={{ color: "#8f8f8f", mt: 3, fontSize: "16px" }}>
          Oops! the page you requested could not be found
        </Typography>
        <Link to="/">
          <Button variant="contained" sx={{ mt: 7,  px:4, py:2, textTransform:'initial', fontWeight:700, }}>
            Back to Home
          </Button>
        </Link>
      </Box>
    </Container>
    </>
  )
}

export default NotFound
import { Avatar, Box, InputLabel, Skeleton, Typography } from "@mui/material";
import React from "react";
import img1 from "../../assets/img/banner2.png";
import ijeoma from '../../assets/img/ijeoma.png'
import { parseISO, format } from 'date-fns';
import { urlFor } from "./sanityImageClient";

const BlogCard = ({data, loading}) => {
const formatDate = (dateString, dateFormat = 'MMMM d, yyyy') => {
  const date = parseISO(dateString);
  return format(date, dateFormat);
};
  return (
    <>
  {
    loading ? (
      <>
    <Box
        sx={{
          border: "1px solid #DEDEDE",
          bgcolor: "#fff",
          boxSizing: "border-box",
          height: "300px",
          borderRadius: "8px",
          p: 2,
          cursor:'pointer',
          transition:'0.2s all linear',
          "&:hover":{
            boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'
          }
        }}
      >
        <Skeleton
          sx={{
            height: "150px",
            borderRadius: "4px",
    
          }}
        />
        <Box sx={{ mt: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
       
              <Skeleton sx={{width:'70px'}}/>
              <Skeleton sx={{width:'40px'}}/>
     
      
    
          </Box>
           <Skeleton sx={{width:'100%', height:'30px'}}/>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: 2, mt: 1.5, justifyContent:'space-between' }}
          >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
              <Skeleton variant="circular" sx={{ width: "20px", height: "20px" }} />
                <Skeleton sx={{width:'30px'}}/>
            </Box>
              <Skeleton sx={{width:'70px'}}/>
          </Box>
        </Box>
      </Box>
      </>
    ):(
          <Box
        sx={{
          border: "1px solid #DEDEDE",
          bgcolor: "#fff",
          boxSizing: "border-box",
          height: "300px",
          borderRadius: "8px",
          p: 2,
          cursor:'pointer',
          transition:'0.2s all linear',
          "&:hover":{
            boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'
          }
        }}
      >
        <Box
          sx={{
            background: `url('${data && urlFor(data?.image?.asset?._ref).url()}')`,
            height: "150px",
            borderRadius: "4px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Box sx={{ mt: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
            {
              data && (
                <>
                {
                  data?.
blogType === "For Diner" ? (
      <Typography
              sx={{
                bgcolor: "#FFF2E5",
                fontSize: "10px",
                color: "#C56000",
                px: 0.8,
                borderRadius: "4px",
              }}
            >
              For Diners
            </Typography>
):(
      <Typography
              sx={{
                bgcolor: "#E8FFE8",
                fontSize: "10px",
                color: "#007E23",
                px: 0.8,
                borderRadius: "4px",
              }}
            >
             {data && data?.blogType}
            </Typography>
)
                }
                </>
              )
            }
      
            <Typography sx={{ fontSize: "10px", color:'#5b5b5b' }}>{data && data?.readDuration}</Typography>
          </Box>
          <Typography
          className="two-line-ellipsis "
            sx={{ fontSize: "14px", fontWeight: 600, mt: 2, color: "#151515" }}
          >
          {data?.name}
          </Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: 2, mt: 1.5 }}
          >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
              <Avatar src={ijeoma} sx={{ width: "20px", height: "20px" }} />
              <InputLabel
                sx={{ fontSize: "10px", color: "#151515", fontWeight: 500 }}
              >
              Ijeoma O.
              </InputLabel>
            </Box>
            <InputLabel sx={{ fontSize: "10px", color: "#5b5b5b" }}>
           {data && formatDate(data?.date)}
            </InputLabel>
          </Box>
        </Box>
      </Box>
    )
  }
    </>
  );
};

export default BlogCard;

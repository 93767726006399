import { Backdrop } from '@mui/material';
import React, {useState} from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const Loader = () => {
   let [color, setColor] = useState("#FCEDFE");

     const [open, setOpen] = React.useState(true);

  return (
 <>
 
 <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={open}

      >
  <PropagateLoader
        color={color}
        loading={true}
        cssOverride={override}
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      </Backdrop>
 </>
  )
}

export default Loader
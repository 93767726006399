import React, { useEffect, useState } from "react";
import Container from "../../Reservation/Container";
import {
  Box,
  Typography,
  LinearProgress,
  Skeleton,
  Button,
  TextField,
  InputLabel,
  Paper,
  IconButton
} from "@mui/material";
import Icons from "../../constant/Icons";
import { HiOutlineLocationMarker } from "react-icons/hi";
import EventCheckout from './EventCheckout'
import { getSingleEvent } from "../../assets/img/api";
import { useParams } from "react-router-dom";
import { formatDateEvent } from "../../Reservation/Pages/Reservation";
import { convertTo12HourFormat } from "../../Pages/Event/Event";
import plus from "../../Reservation/Assets/img/plus.svg";
import minus from "../../Reservation/Assets/img/minus.svg";


const EventOverview = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOverview, setIsOverview] = useState(false);
    const [selectedTickets, setSelectedTickets] = useState([]);
  const {id, vendor} = useParams()
  const [event, setEvent] = useState(null)
    const [error, setError] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleGetDetails = async () =>{
    setIsLoading(true)
    getSingleEvent(id)
    .then((res)=>{
      setEvent(res?.data?.data?.event)
      console.log(res)
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setIsLoading(false)
    })
  }

    const [userDetails, setUserDetails] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
  });
  const { fullName, email, phoneNumber } = userDetails;

  useEffect(()=>{
    handleGetDetails()
  },[])

  const darkMode = false;

    const increaseQuantity = (ticket) => {
    setSelectedTickets((prevSelectedTickets) => {
      const existingTicket = prevSelectedTickets.find(
        (t) => t.id === ticket.id
      );
      if (existingTicket) {
        // If the ticket already exists, increase its quantity
        return prevSelectedTickets.map((t) =>
          t.id === ticket.id ? { ...t, quantity: t.quantity + 1 } : t
        );
      } else {
        // If the ticket doesn't exist, add it with a quantity of 1
        return [...prevSelectedTickets, { ...ticket, quantity: 1 }];
      }
    });
  };

  // Function to handle decreasing the quantity of a ticket
  const decreaseQuantity = (ticket) => {
    setSelectedTickets((prevSelectedTickets) => {
      const existingTicket = prevSelectedTickets.find(
        (t) => t.id === ticket.id
      );
      if (existingTicket.quantity === 1) {
        // If quantity is 1, remove the ticket from the array
        return prevSelectedTickets.filter((t) => t.id !== ticket.id);
      } else {
        // Otherwise, decrease the quantity
        return prevSelectedTickets.map((t) =>
          t.id === ticket.id ? { ...t, quantity: t.quantity - 1 } : t
        );
      }
    });
  };

    const totalPrice = selectedTickets.reduce(
    (total, ticket) => total + parseFloat(ticket.price) * ticket.quantity,
    0
  );
  const totalQuantity = selectedTickets.reduce(
    (total, ticket) => total + ticket.quantity,
    0
  );
  return (
    <>
      <Container>
        <Box
          sx={{
            bgcolor: "#fff",
            height: "100vh",
            overflow: "scroll",
            py: 3,
            boxSizing: "border-box",
          }}
          className="hide_scrollbar"
        >
 {
  isOverview ? (
    <>
           <Button
           onClick={()=>setIsOverview(false)}
            startIcon={<img src={Icons.back} />}
            sx={{
              fontWeight: 700,
              fontSize: "12px",
              color: "#000",
              textTransform: "initial",
              mb: 2,
              ml: 2,
            }}
          >
    Order Confirmation
          </Button>

          <EventCheckout event={event} tickets={selectedTickets} userDetails={userDetails} vendor_id={vendor} />
    </>
  ):(
    <>
             <Button
            startIcon={<img src={Icons.back} />}
            sx={{
              fontWeight: 700,
              fontSize: "12px",
              color: "#000",
              textTransform: "initial",
              mb: 2,
              ml: 2,
            }}
          >
            Back to main page
          </Button>
          {isLoading ? (
            <>
            <Box sx={{p:2}}>

              <LoadingFrame />
            </Box>
            </>
          ) : (
            <>
              <Box sx={{ px: 3 }}>
                <Box
                  sx={{
                    border: "1px solid #dedede",
                    height: "250px",
                    borderRadius: "8px",
                    background:`url(${event?.banner})`,
                    backgroundSize:'cover',
                    backgroundPosition:'center'
                  }}
                />
                <Box sx={{ mt: 2 }}>
                  <InputLabel sx={{ fontWeight: 700, fontSize: "16px" }}>
                  {event?.name}
                  </InputLabel>
                  <Box
                    sx={{
                      mt: 0.5,
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <img src={Icons.location2} />
                    <Typography sx={{ color: "#75007E" }}>
                {event?.location}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ color: "#5b5b5b", mt: 1.5, fontWeight: 300 }}
                  >
                 {event?.description}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                      mt: 2,
                    }}
                  >
                    <img src={Icons.calendar} width={20} />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#5b5b5b",
                        fontWeight: 300,
                      }}
                    >
                           {event?.frequency === "one-off" ? <>{formatDateEvent(event?.event_time)}</> : <>{`Every ${event?.event_day}s`}</>}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                      mt: 1,
                    }}
                  >
                    <img src={Icons.time} width={20} />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#5b5b5b",
                        fontWeight: 300,
                      }}
                    >
          {event?.kick_off_time &&
                convertTo12HourFormat(event?.kick_off_time)}{" "}
              - {event?.close_time && convertTo12HourFormat(event?.close_time)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                      mt: 1,
                    }}
                  >
                    <img src={Icons.ticket} width={20} />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#5b5b5b",
                        fontWeight: 300,
                      }}
                    >
                      Tickets from<b style={{ color: "#75007E" }}> N{parseFloat(event?.ticket_min_price).toLocaleString()}</b>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ borderTop: "5px solid #dedede", mt: 5, px: 3, pt: 4 }}>
                <Typography
                  // className={darkMode ? "dark" : "light"}
                  sx={{ fontSize: "14px", fontWeight: 700 }}
                >
                  Buy your Ticket
                </Typography>

                <Typography
                  sx={{
                    // color: darkMode ? "#fff" : "#5b5b5b",
                    mt: 0.5,
                    fontSize: "13px",
                    color: "#5b5b5b",
                    fontWeight: 300,
                  }}
                >
                  Which of the ticket(s) will you like to buy?
                </Typography>
    <Box sx={{ mt: 2 }}>
                {event?.ticket?.map((ticket, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "center",
                      borderBottom: "0.5px solid #dedede",
                      pb: 1,
                      mb: 2,
                      ":last-child": {
                        border: "none",
                      },
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                        {`${ticket?.name} Ticket `}{" "}
                        <span style={{ fontWeight: 300 }}>
                          - Admit {ticket?.admission_count}
                        </span>
                      </Typography>
                      <Typography sx={{ color: "#8f8f8f", fontSize: "12px" }}>
                        N{parseFloat(ticket?.price).toLocaleString()}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        border: "1px solid",
                        borderColor: "primary.main",
                        borderRadius: "4px",
                        width: "86px",
                        height: "31px",
                        display: "flex",
                        boxSizing: "border-box",
                        alignItems: "center",
                        justifyContent: "space-between",
                        p: 0.5,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          if (
                            selectedTickets.find((t) => t.id === ticket.id)
                              ?.quantity > 0
                          ) {
                            decreaseQuantity(ticket);
                          }
                        }}
                      >
                        <img src={minus} />
                      </IconButton>
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontSize: "10px",
                          fontWeight: 600,
                        }}
                      >
                        {selectedTickets.find((t) => t.id === ticket.id)
                          ?.quantity || 0}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          const ticketInCart = selectedTickets.find(
                            (t) => t.id === ticket.id
                          );
                          const currentQuantity = ticketInCart?.quantity || 0;

                          if (currentQuantity < ticket.ticket_unsold) {
                            increaseQuantity(ticket);
                          } else {
                            alert("No more tickets available for this type.");
                          }
                        }}
                      >
                        <img src={plus} alt="Add" />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box>
                
              </Box>
              <Box sx={{ borderTop: "5px solid #dedede", mt: 5, px: 3, pt: 4, mb:14 }}>
                <Typography
                  // className={darkMode ? "dark" : "light"}
                  sx={{ fontSize: "14px", fontWeight: 700 }}
                >
                  Guest Details
                </Typography>

                <Typography
                  sx={{
                    // color: darkMode ? "#fff" : "#5b5b5b",
                    mt: 0.5,
                    fontSize: "13px",
                    color: "#5b5b5b",
                    fontWeight: 300,
                  }}
                >
                  Please provide details for your ticket
                </Typography>

                <Box sx={{ mt: 3, width: "80%" }}>
                  <InputLabel
                    // className={darkMode ? "dark" : "light"}
                    sx={{ fontSize: "12px", mb: 0.5, fontWeight: 500 }}
                  >
                    Full Name
                  </InputLabel>
                  <TextField
                    fullWidth
                      value={fullName}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      fullName: e.target.value,
                    })
                  }
                    placeholder="Enter your full name"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        border: darkMode
                          ? "1px solid #fff"
                          : "1px solid #151515",
                        color: darkMode ? "#fff" : "#151515",
                        fontSize: "13px",
                      },
                    }}
                  />
                  <InputLabel
                    className={darkMode ? "dark" : "light"}
                    sx={{ fontSize: "12px", mb: 0.5, fontWeight: 500, mt: 1.5 }}
                  >
                    Email Address
                  </InputLabel>
                  <TextField
                    fullWidth
                     value={email}
                  error={error}
                  helperText={error ? "Not a valid email" : ""}
           
                  onChange={(e) => {
                    setUserDetails({
                      ...userDetails,
                      email: e.target.value,
                    });
                    setError(!validateEmail(e.target.value));
                  }}
                    placeholder="admin@velvett.com"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        border: darkMode
                          ? "1px solid #fff"
                          : "1px solid #151515",
                        color: darkMode ? "#fff" : "#151515",
                        fontSize: "13px",
                      },
                    }}
                  />
                  <InputLabel
                    // className={darkMode ? "dark" : "light"}
                    sx={{ fontSize: "12px", mb: 0.5, fontWeight: 500, mt: 1.5 }}
                  >
                    Phone Number (Optional)
                  </InputLabel>
                  <TextField
                    fullWidth
                    type="number"
    value={phoneNumber}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      phoneNumber: e.target.value,
                    })
                  }
                    placeholder="080XXXXXXX"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        border: darkMode
                          ? "1px solid #fff"
                          : "1px solid #151515",
                        color: darkMode ? "#fff" : "#151515",
                        fontSize: "13px",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Paper
                sx={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: { lg: "35%", md: "40%", sm: "50%", xs: "100%" },
                  margin: "0 auto",
                  height: "100px",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: 0,
                  background: darkMode ? "#000" : "#fff",
                  boxShadow: "none",
                }}
              >
                {/* <Link to={`/reservation/${uid}`}> */}
                <Button
                  onClick={() => setIsOverview(true)}
               disabled={
                  !fullName || !email || error || totalPrice === 0 || isLoading
                }
                  sx={{
                    width: "300px",
                    height: "63px",
                    background: darkMode ? "#fff" : "#000",
                    color: darkMode ? "#151515" : "#fff",
                    textTransform: "initial",
                    fontWeight: 700,
                    "&:hover": {
                      backgroundColor: darkMode ? "#ffffffb7" : "#151515b7",
                    },
                    "&:disabled": {
                      color: "#fff",
                      bgcolor: "#5b5b5b",
                      cursor: "not-allowed",
                    },
                  }}
                >
                  Continue
                </Button>
                {/* </Link> */}
              </Paper>
            </>
          )}
    </>
  )
 }
        </Box>
      </Container>
    </>
  );
};

export default EventOverview;

const LoadingFrame = () => {
  return (
    <>
      <Skeleton animation="wave" variant="rounded" height={300} />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={15}
        sx={{ mt: 1 }}
        width="70%"
      />
      <Skeleton animation="wave" variant="rounded" height={65} sx={{ mt: 1 }} />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={10}
        sx={{ mt: 1, width: "50%" }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={10}
        sx={{ mt: 1, width: "50%" }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={10}
        sx={{ mt: 1, width: "50%" }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={200}
        sx={{ mt: 4 }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={10}
        sx={{ mt: 4, width: "50%" }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={50}
        sx={{ mt: 1, width: "90%" }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={50}
        sx={{ mt: 1, width: "90%" }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={50}
        sx={{ mt: 1, width: "90%" }}
      />
    </>
  );
};

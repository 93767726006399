import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import bg from "../assets/img/vectors/btn_tree.svg";

// Define the custom styled button with the gradient fill
const GradientButton = styled(motion(Button))({
  backgroundImage: `url('${bg}')`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPositionY: "50px",
  backgroundColor: "#75007E",
  border: "0.5px solid #75007E",
  borderRadius: 3,
  color: "white",
  height: { lg: 42, md: 42, sm: 30, xs: 30 },

  borderRadius: "6px",
  fontFamily: "outfit",
  fontWeight: 700,
  textTransform: "initial",
  transition: "0.4s all ease-in",
  "&:hover": {
    backgroundColor: "#75007E",
    backgroundPositionY: "bottom",
  },
});

// Your component where you use the custom styled button
function CustomButton({ children, ...props }) {
  return (
    <GradientButton
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9, rotate: "2.5deg" }}
      transition={{
        duration: 0.2,
        ease: "easeInOut",
      }}
      component={motion.button}
      {...props}
      sx={{ height: { lg: "50px", md: "50px", sm: "40px", xs: "40px" } ,   width: { lg: "200px", md: "200px", sm: "150px", xs: "120px" },}}
    >
      {children}
    </GradientButton>
  );
}

export default CustomButton;

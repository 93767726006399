import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Aos from "aos";
import "aos/dist/aos.css";
import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Common/Navbar";
import Banner from "../../Components/Common/Banner";
import bg from "../../assets/img/vectors/bg2.svg";
import mock1 from "../../assets/img/vectors/mock1.png";
import mock2 from "../../assets/img/vectors/mock2.png";
import mock3 from "../../assets/img/vectors/mock3.png";
import mock4 from "../../assets/img/vectors/mock4.png";
import star from "../../assets/img/vectors/star.svg";
import { BrandConfig } from "../../assets/img/brand/BrandConfig";
import line from "../../assets/img/vectors/line.svg";
import Footer from "../../Components/Common/Footer";
import imgBox from "../../assets/img/vectors/box.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logos from "../../assets/img/vectors/logos.svg";
import { AnimeConfig } from "../../assets/img/anime/AnimeConfig";
import x from "../../assets/img/vectors/x.png";
import ThreeDCard from "../../Components/Common/ThreeDCard";
import HomeBlog from "../../Components/Blog/HomeBlog";

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const settings = {
    dots: false,
    Infinite: true,
    arrows: false,
    slidesToShow: isMobile ? 8 : 15,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  const settings2 = {
    dots: false,
    Infinite: true,
    arrows: false,
    slidesToShow: isMobile ? 8 : 15,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    rtl: true,
  };
  const settings3 = {
    dots: false,
    Infinite: true,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    rtl: true,
  };
  const settings5 = {
    dots: false,
    Infinite: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    rtl: true,
  };
  const settings4 = {
    dots: false,
    Infinite: true,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    // rtl: true,
  };

  return (
    <>
      <Navbar />
      <Banner data-aos="zoom-in" />
      <Box
        sx={{
          bgcolor: "#151515",
          backgroundImage: `url('${bg}')`,
          boxSizing: "border-box",
          backgroundSize: { lg: "contain", md: "cover", xs: "cover" },
        }}
      >
        <Box
          sx={{
            width: {xl:'55%', lg: "65%", md: "80%", xs: "80%" },

            margin: "0 auto",

            pt: 12,
          }}
        >
          <Box sx={{ pb: 7, display: "flex", justifyContent: "space-between" , flexDirection:{lg:'row', md:'row',  sm:'column', xs:'column'}}}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box data-aos="fade-up">
                  <ThreeDCard
                    width={{
                      lg: "390px",
                      md: "380px",
                      sm: "400px",
                      xs: "300px",
                    }}
                    height={{ md: "520px", xs: "350px", sm:'450px' }}
                    image={mock1}
                  >
                    <Typography
                      sx={{
                        fontFamily: "butler",
                        color: "#dedede",
                        fontSize: { md: "22px", xs: "16px" },
                        textAlign: "center",
                        width: { md: "60%", xs: "80%" },
                        margin: "0 auto",
                        pt: 4,
                        lineHeight: { md: "28px", xs: "20px" },
                        transform: "translateZ(50px)",
                      }}
                    >
                      Secure Exclusive Tables at Premier Upscale Dining Spots
                      and lounges in your city.
                    </Typography>
                  </ThreeDCard>
                </Box>

                <Box sx={{ mt: 4 }} data-aos="fade-up">
                  <ThreeDCard
                    width={{ lg: "390px", md: "380px", sm: "400px", xs: "100%" }}
                    height={{ md: "420px", xs: "330px", sm:'450px' }}
                    image={mock3}
                  >
                    <Typography
                      sx={{
                        fontFamily: "butler",
                        color: "#dedede",
                        fontSize: { md: "22px", xs: "16px" },
                        textAlign: "center",
                        width: { md: "60%", xs: "80%" },
                        margin: "0 auto",
                        pt: 4,
                        lineHeight: { md: "28px", xs: "20px" },
                      }}
                    >
                      Invite Guests to your Table with Ease and Style.
                    </Typography>
                  </ThreeDCard>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",

                flexDirection: "column",

                mt:{lg:0, md:0, sm:5, xs:5}
              }}
            >
              <Box data-aos="fade-up">
                <ThreeDCard
                  width={{ lg: "390px", md: "380px", sm: "400px", xs: "300px" }}
                  height={{ md: "420px", xs: "300px" ,  sm:'450px'}}
                  image={mock2}
                >
                  <Typography
                    sx={{
                      fontFamily: "butler",
                      color: "#dedede",
                      fontSize: { md: "22px", xs: "16px" },
                      textAlign: "center",
                      width: { md: "60%", xs: "80%" },
                      margin: "0 auto",
                      pt: 4,
                      lineHeight: { md: "28px", xs: "20px" },
                    }}
                  >
                    Effortlessly Split bills with Friends Directly from the App.
                  </Typography>
                </ThreeDCard>
              </Box>

              <Box
                sx={{
                  mt: 4,
                }}
                data-aos="fade-up"
              >
                <ThreeDCard
                  width={{ lg: "390px", md: "380px", sm: "400px", xs: "300px" }}
                  height={{ md: "520px", xs: "300px", sm:'450px' }}
                  image={mock4}
                >
                  <Typography
                    sx={{
                      fontFamily: "butler",
                      color: "#dedede",
                      fontSize: { md: "22px", xs: "16px" },
                      textAlign: "center",
                      width: { md: "60%", xs: "80%" },
                      margin: "0 auto",
                      pt: 4,
                      lineHeight: { md: "28px", xs: "20px" },
                    }}
                  >
                    Connect with New Friends by accepting People's Requests to
                    join your table.
                  </Typography>
                </ThreeDCard>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          width: { md: "70%", xs: "100%" },
          margin: "0 auto",
          p: 2,
          boxSizing: "border-box",
          pt: 5,
          pb: 5,
        }}
      >
        <Grid container spacing={6}>
          <Grid item md={4} xs={12} data-aos="fade-up">
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 2,
                  justifyContent: { md: "left", xs: "center" },
                }}
              >
                <img src={star} />
                <Typography
                  color="primary"
                  sx={{
                    fontWeight: 700,
                    fontSize: "16px",
                    textAlign: { md: "left", xs: "center" },
                  }}
                >
                  Vendor Exclusivity
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontFamily: "butler",
                  color: "#151515",
                  fontSize: { md: "22px", xs: "16px" },
                  fontWeight: 500,
                  lineHeight: { md: "30px", xs: "20px" },
                  textAlign: { md: "justify", xs: "center" },
                  width: { md: "fit-content", xs: "80%" },
                  margin: "0 auto",
                  mt: 2,
                }}
              >
                VibezsUp will partner with 250+ Premium Establishments to Curate
                the Best Tables for you.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            {isMobile ? (
              <>
                <Box
                  data-aos="fade-up"
                  sx={{
                    ml: { md: 8, xs: 0 },
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 2,
                    // mx:-15
                  }}
                >
                  <Slider {...settings3}>
                    {BrandConfig?.slice(0, 7).map((img, index) => (
                      <Box
                        sx={{
                          mx: 4,
                          height: "50px",
                          width: "50px",
                        }}
                      >
                        <img src={img} width={50} />
                      </Box>
                    ))}
                  </Slider>
                  <Slider {...settings4}>
                    {BrandConfig?.slice(7, 14).map((img, index) => (
                      <Box
                        sx={{
                          mx: 4,
                          height: "50px",
                          width: "50px",
                        }}
                      >
                        <img src={img} width={50} style={{}} />
                      </Box>
                    ))}
                  </Slider>
                  <Slider {...settings5}>
                    {BrandConfig?.slice(15, 19).map((img, index) => (
                      <Box
                        sx={{
                          mx: 4,

                          height: "50px",
                          width: "50px",
                          pt: 2,
                        }}
                      >
                        <img src={img} width={60} style={{}} />
                      </Box>
                    ))}
                  </Slider>
                </Box>
              </>
            ) : (
              <>
                <Box
                  data-aos="zoom-in"
                  sx={{
                    ml: { md: 8, xs: 0 },
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 5,
                  }}
                >
                  <Box
                    data-aos="fade-up"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {BrandConfig?.slice(0, 7).map((img, index) => (
                      <img src={img} width={50} />
                    ))}
                  </Box>
                  <Box
                    data-aos="fade-up"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {BrandConfig?.slice(7, 14).map((img, index) => (
                      <img src={img} width={50} />
                    ))}
                  </Box>
                  <Box
                    data-aos="fade-up"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {BrandConfig?.slice(14, 19).map((img, index) => (
                      <img src={img} width={50} />
                    ))}
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        </Grid>

        <Box
          data-aos="fade-up"
          sx={{
            mt: 8,
            mb: -5,
            background: `url('${line}')`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "1px",
          }}
        />
      </Box>
      <div className="anime">
        <img src={x} width={"100%"} />
      </div>
      <HomeBlog/>
      <Box
        sx={{
          width: "70%",
          margin: "0 auto",
          background: `url('${line}')`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          py: { md: 5, xs: 0 },
        }}
      />

      <Footer />
    </>
  );
};

export default Home;

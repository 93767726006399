import { Box, Divider, Typography, TextField, InputLabel , Button, Paper} from "@mui/material";
import React , {useState} from "react";
import party from "../../assets/img/party.png";
import Icons from "../../constant/Icons";
import { formatDateEvent } from "../../Reservation/Pages/Reservation";
import { buyWidgetTicket } from "../../assets/img/api";
import Loader from "../Common/Loader";
import { useParams } from "react-router-dom";

const EventCheckout = ({ event, tickets, userDetails, vendor_id }) => {
  const darkMode = false;
  const {uid} = useParams()

  const totalPrice = tickets.reduce(
    (total, ticket) => total + parseFloat(ticket.price) * ticket.quantity,
    0
  );

  const [isLoading, setIsLoading] = useState(false);


    const handleBuyTicket = async () => {
    setIsLoading(true);
    const refinedTicket = tickets.map((ticket) => ({
      id: ticket.id,
      quantity: ticket.quantity,
    }));

    await buyWidgetTicket(vendor_id, refinedTicket, userDetails?.fullName, userDetails?.email, userDetails?.phoneNumber, event?.id)
      .then((res) => {
        console.log(res);
        if (res?.data?.status) {
          const {
            amount,
            email,
            full_name,
  
            transaction_reference,
            redirect_link,
          } = res?.data?.data;
           const pub_key = process.env.REACT_APP_POOLER_KEY;
          window?.Pooler?.Popup({
            amount,
            email,
            pub_key,
            redirect_link: `https://vibezsup.com/event/payment_check?uid=${uid}&transaction_reference=${transaction_reference}&email=${email}`,
            transaction_reference,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
        {isLoading && <Loader />}
      <Box sx={{ p: 3 }}>
        <Typography sx={{ color: "#5b5b5b" }}>
          Review ticket details before payment
        </Typography>
        <Box
          sx={{ mt: 2, display: "flex", alignItems: "center", columnGap: 3 }}
        >
          <Box
            sx={{
              height: "89px",
              width: "84px",
              background: `url('${event?.banner}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "8px",
            }}
          />
          <Box>
            <Typography sx={{ fontWeight: 700 }}>{event?.name}</Typography>
            <Box
              sx={{
                display: "flex",
                columnGap: 1,
                alignItems: "center",
                mt: 1,
              }}
            >
              <img src={Icons.location2} />
              <Typography sx={{ color: "primary.main" }}>
                {event?.location}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
                mt: 1,
              }}
            >
              <img src={Icons.calendar} width={15} />
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#5b5b5b",
                  fontWeight: 300,
                }}
              >
                {event?.frequency === "one-off" ? (
                  <>{formatDateEvent(event?.event_time)}</>
                ) : (
                  <>{`Every ${event?.event_day}s`}</>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box sx={{}}>
          <Typography sx={{ fontWeight: 600 }}>Ticket Details</Typography>

          {tickets?.map((ticket, index) => (
            <Box sx={{ mt: 1 }} key={index}>
              <Typography>
                <b>{ticket?.name} - </b>Admit {ticket?.admission_count}{" "}
                <b>
                  - x {tickets.find((t) => t.id === ticket.id)?.quantity || 0}
                </b>{" "}
              </Typography>
              <Typography sx={{ color: "#8F8F8F", fontSize: "12px" }}>
                N{" "}
                {parseFloat(
                  ticket?.price *
                    (tickets.find((t) => t.id === ticket.id)?.quantity || 0)
                ).toLocaleString()}
              </Typography>
            </Box>
          ))}

          <Typography sx={{ mt: 3, color: "primary.error", fontWeight: 700 }}>
            Total Bill - N{totalPrice.toLocaleString()}
          </Typography>
        </Box>

        <Box
          sx={{ borderTop: "5px solid #dedede", mt: 5, px: 3, pt: 4, mb: 14 , mx:-3}}
        >
          <Typography
            // className={darkMode ? "dark" : "light"}
            sx={{ fontSize: "14px", fontWeight: 700 }}
          >
            Guest Details
          </Typography>

          <Typography
            sx={{
              // color: darkMode ? "#fff" : "#5b5b5b",
              mt: 0.5,
              fontSize: "13px",
              color: "#5b5b5b",
              fontWeight: 300,
            }}
          >
            Please provide details for your ticket
          </Typography>

          <Box sx={{ mt: 3, width: "80%" }}>
            <InputLabel
              // className={darkMode ? "dark" : "light"}
              sx={{ fontSize: "12px", mb: 0.5, fontWeight: 500 }}
            >
              Full Name
            </InputLabel>
            <TextField
              fullWidth
                sx={{bgcolor:'#dedede'}}
              value={userDetails?.fullName}
              placeholder="Enter your full name"
              InputProps={{
                style: {
                  borderRadius: "10px",
                  border: darkMode ? "1px solid #fff" : "1px solid #151515",
                  color: darkMode ? "#fff" : "#151515",
                  fontSize: "13px",
                },
              }}
            />
            <InputLabel
              className={darkMode ? "dark" : "light"}
              sx={{ fontSize: "12px", mb: 0.5, fontWeight: 500, mt: 1.5 }}
            >
              Email Address
            </InputLabel>
            <TextField
              fullWidth
                sx={{bgcolor:'#dedede'}}
              value={userDetails?.email}
              placeholder="admin@velvett.com"
              InputProps={{
                style: {
                  borderRadius: "10px",
                  border: darkMode ? "1px solid #fff" : "1px solid #151515",
                  color: darkMode ? "#fff" : "#151515",
                  fontSize: "13px",
                },
              }}
            />
            <InputLabel
              // className={darkMode ? "dark" : "light"}
              sx={{ fontSize: "12px", mb: 0.5, fontWeight: 500, mt: 1.5 }}
            >
              Phone Number (Optional)
            </InputLabel>
            <TextField
              fullWidth
              sx={{bgcolor:'#dedede'}}
              value={userDetails?.phoneNumber}
              placeholder="080XXXXXXX"
              InputProps={{
                style: {
                  borderRadius: "10px",
                  border: darkMode ? "1px solid #fff" : "1px solid #151515",
                  color: darkMode ? "#fff" : "#151515",
                  fontSize: "13px",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
            <Paper
                sx={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: { lg: "45%", md: "40%", sm: "50%", xs: "100%" },
                  margin: "0 auto",
                  height: "100px",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: 0,
                  background: darkMode ? "#000" : "#fff",
                  boxShadow: "none",
                }}
              >
         
                <Button
                  onClick={() => handleBuyTicket()}
                  sx={{
                    width: "300px",
                    height: "63px",
                    background: darkMode ? "#fff" : "#000",
                    color: darkMode ? "#151515" : "#fff",
                    textTransform: "initial",
                    fontWeight: 700,
                    "&:hover": {
                      backgroundColor: darkMode ? "#ffffffb7" : "#151515b7",
                    },
                    "&:disabled": {
                      color: "#fff",
                      bgcolor: "#5b5b5b",
                      cursor: "not-allowed",
                    },
                  }}
                >
                  Buy Ticket - N{totalPrice.toLocaleString()}
                </Button>
                {/* </Link> */}
              </Paper>
    </>
  );
};

export default EventCheckout;

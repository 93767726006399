import React,{useEffect} from "react";
import bg from "../../assets/img/vectors/bg.svg";
import bg2 from "../../assets/img/vectors/bg-m.svg";
import launchImg from "../../assets/img/vectors/launch2.png";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import CustomInput from "../CustomInput";
import playstore from "../../assets/img/vectors/googlestore 2.svg";
import applestore from "../../assets/img/vectors/appstore 2.svg";
import Aos from 'aos'
import 'aos/dist/aos.css'
import { motion } from "framer-motion";


const Banner = () => {
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down("1271"));
  const isSM = useMediaQuery(theme.breakpoints.down("700"));

     useEffect(()=>{
    Aos.init({duration: 1000});
  },[])
  return (
    <>
      {isSM ? (
        <>
          <Box
            sx={{
              height: "fit-content",
              boxSizing: "border-box",
              width: "100%",
              background: `url('${bg2}')`,
              backgroundSize: "contain",
              backgroundPositionY: { md: "50px", lg: "20px", xs: "100px" },
              backgroundPositionX: "center",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed ",
              transition: "0.2s all linear",
            }}
          >
            <Box
              sx={{
                width: "90%",
                margin: "0 auto",
                mt: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",

                py: 7,
              }}
            >
              <img src={launchImg} width={150} />
              <Typography
              data-aos="zoom-in"
                className="     text_3_m"
                sx={{
                  color: "#151515",
                  fontWeight: 800,
                  fontSize: { lg: "48px", md: "40px", xs: "32px" },
                  lineHeight: "45px",
                  textAlign: "center",
                  mt: 3,
                }}
              >
                Book & Reserve the most <span className="text_2_m">e</span>
                xclusive Tables at the
                <span className="text"> Elitist Spots</span> in
                <span className=""> your City</span>🌴
              </Typography>
              <Typography
                sx={{
                  color: "#000000b7",
                  fontWeight: 600,
                  mt: 3,
                  fontSize: { md: "22px", sm: "18px" },
                }}
              >
                Use VibezsUp to Find your Happy Place
              </Typography>

              <Box sx={{ mt: 3, width: "100%" }}>
                <CustomInput />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  columnGap: 3,
                }}
              >
                <a
                  href="https://apps.apple.com/ng/app/vibezsup/id6498631643
"
                >
                  <img src={applestore} width={100} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.vibesup.app">
                  {" "}
                  <img src={playstore}  width={100}/>
                </a>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
      
            sx={{
              height: "fit-content",
              boxSizing: "border-box",
              width: "100%",
              background: `url('${bg}')`,
              backgroundSize: { md: "auto 1400px", sm: " auto 1300px" },
              backgroundPositionY: { md: "100px", lg: "120px", sm: "100px" },
              backgroundPositionX: "center",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed ",
              transition: "0.2s all linear",
              border: {
                // lg: "5px solid",
                // md: "15px solid red",
                // sm: "10px  solid",
                // xs: "20px solid",
              },
            }}
          >
            <Box
                component={motion.div}
          animate={{y:0}}
          initial={{y:100}}
          transition={{duration:1}}
          exit={{y:100}}
              sx={{
                width: "70%",
                margin: "0 auto",
                mt: 15,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                pt: 9,
                py: 17,
              }}
            >
              <img src={launchImg} width={200} />
              <Typography
                sx={{
                  color: "#151515",
                  fontWeight: 800,
                  fontSize: { lg: "48px", md: "40px", sm: "26px" },
                  lineHeight: "60px",
                  textAlign: "center",
                  mt: 3,
                }}
              >
                Book & Reserve the most <span className="text_2">e</span>
                xclusive <br /> Tables at the
                <span className="text_3">
                  <span className="text"> Elitist Spots </span>
                </span>
                in your City🌴
              </Typography>
              <Typography
                sx={{
                  color: "#000000b7",
                  fontWeight: 600,
                  mt: 5,
                  fontSize: { md: "22px", sm: "18px" },
                }}
              >
                Use VibezsUp to Find your Happy Place
              </Typography>

              <Box sx={{ mt: 10, width: { md: "50%", sm: "70%" } }}>
                <CustomInput />
              </Box>
              <Box
                sx={{
                  mt: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  columnGap: 3,
                }}
              >
                <motion.a
   whileHover={{ scale: 1.1,  }}
            whileTap={{scale:0.9, rotate:'2.5deg'}}
            transition={{
              duration:0.2,
              ease:'easeInOut'
            }}
                  href="https://apps.apple.com/ng/app/vibezsup/id6498631643
"
                >
                  <img src={applestore} />
                </motion.a >
                <motion.a
                   whileHover={{ scale: 1.1,  }}
            whileTap={{scale:0.9, rotate:'2.5deg'}}
            transition={{
              duration:0.2,
              ease:'easeInOut'
            }}
                href="https://play.google.com/store/apps/details?id=com.vibesup.app">
                  {" "}
                  <img src={playstore} />
                </motion.a>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Banner;

import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import vibezblog from "../../assets/img/vectors/VibezsBlog.svg";
import CustomButtonGradient from "../CustomButtonGradient";
import BlogCard from "./BlogCard";
import { Link } from "react-router-dom";
import Client from "./Client";
import blogImg from '../../assets/img/vectors/Blog.svg'

const HomeBlog = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  useEffect(() => {
    setIsLoading(true)
    Client.fetch(
      `*[_type == "event"] {
    name,
    slug, blogType,date, description,image,details, readDuration}
`
    )
      .then((data) =>{
 setBlogs(data);
 setIsLoading(false)
      })
      .catch((err)=>{
        console.log(err);
        setIsLoading(false)
      });
  }, []);

  console.log(blogs);
  return (
    <>
      <Box
        sx={{
          width: { xl: "70%", md: "80%", xs: "90%" },
          margin: "0 auto",
          p: 0.1,
          boxSizing: "border-box",
          background:
            "linear-gradient(90deg, rgba(0,218,255,1) 0%, rgba(49,186,253,1) 16%, rgba(113,144,250,1) 38%, rgba(164,110,247,1) 58%, rgba(200,86,245,1) 76%, rgba(223,71,244,1) 91%, rgba(231,66,244,1) 100%)",
          borderRadius: "16px",
        }}
      >
      {
        isMobile ? (
            <Box
          sx={{
            background: "#fff",
            
            borderRadius: "16px",
            backgroundImage:`url('${blogImg}')`,
         backgroundPosition:'center',
            backgroundSize: "cover",
            boxSizing: "border-box",
            display: "grid",
            placeItems: "center",
            px: 6,
            py:5
          }}
        >
          <Grid container spacing={2} sx={{placeContent:'center'}}>
            <Grid item lg={4.5} md={3} sm={12}  xs={12} sx={{mb:3}}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
            
            
                }}
              >
                <img src={vibezblog} width={160} />
                <Typography
                  sx={{
                    width:{lg: "50%", md:'100%',xs:'100%'},
                    color: "#5b5b5b",
                    fontSize: "12px",
                    lineHeight: "16px",
                    textAlign:'center',
                    mt: 1,
                  }}
                >
                  Enjoy the latest gist on where the Vibezs is in your City
                </Typography>

         
              </Box>
            </Grid>
            {blogs?.slice(0, 3)?.map((blog, index) => (
              <>
                <Grid    item lg={2.5}  md={3} sm={6} xs={12} key={index}>
                  <Link to={`/blog/${blog?.slug?.
current}`}>
                    <BlogCard data={blog} loading={isLoading} />
                  </Link>
                </Grid>

              </>
            ))}
          </Grid>
              <Box sx={{       width:{lg: "50%", md:'100%',xs:'60%'}, mt: 5 }}>
                  <Link to="/blogs">
                    <CustomButtonGradient>Browse Blog</CustomButtonGradient>
                  </Link>
                </Box>
        </Box>
        ):(
            <Box
          sx={{
            background: "#fff",
            height: "400px",
            borderRadius: "16px",
            backgroundImage:`url('${blogImg}')`,
         
            backgroundSize: "cover",
            boxSizing: "border-box",
            display: "grid",
            placeItems: "center",
            px: 6,
          }}
        >
          <Grid container spacing={2}>
            <Grid item lg={4.5} md={3} >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  justifyContent: "center",
                  height: "300px",
                }}
              >
                <img src={vibezblog} width={160} />
                <Typography
                  sx={{
                    width:{lg: "50%", md:'100%',xs:'100%'},
                    color: "#5b5b5b",
                    fontSize: "16px",
                    lineHeight: "20px",
                    mt: 1,
                  }}
                >
                  Enjoy the latest gist on where the Vibezs is in your City
                </Typography>

                <Box sx={{        width:{lg: "50%", md:'100%',xs:'100%'}, mt: 3 }}>
                  <Link to="/blogs">
                    <CustomButtonGradient>Browse Blog</CustomButtonGradient>
                  </Link>
                </Box>
              </Box>
            </Grid>
            {blogs?.slice(0, 3)?.map((blog, index) => (
              <>
                <Grid item lg={2.5}  md={3} sm={6} key={index}>
                  <Link to={`/blog/${blog?.slug?.
current}`}>
                    <BlogCard data={blog} loading={isLoading} />
                  </Link>
                </Grid>
              </>
            ))}
          </Grid>
             
        </Box>
        )
      }
      </Box>
    </>
  );
};

export default HomeBlog;

import {
  Box,
  Button,
  Divider,
  Grid,
  InputLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Icons from "../../../constant/Icons";
import { getSingleEvent } from "../../../assets/img/api";
import { useParams } from "react-router-dom";
import { convertTo12HourFormat } from "../Event";
import MapContainer from "../../../Components/Common/MapContainer";
import { formatDateEvent } from "../../../Reservation/Pages/Reservation";

const EventDetails = ({ data, handleNext }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <>
      <Box sx={{}}>
        <Grid container spacing={isMobile ? 4 : 8}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Box sx={{}}>
              <Box
                sx={{
                  height: "300px",
                  bgcolor: "#dedede",
                  borderRadius: "10px",
                  background: `url(${data?.banner})`,
                  backgroundSize: "cover",
                }}
              />
              <Box sx={{ mt: 2 }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    height: "44px",
                    borderRadius: "10px",
                    textTransform: "initial",
                  }}
                  endIcon={<img src={Icons.ticket3} />}
                  onClick={handleNext}
                >
                  Buy Ticket
                </Button>
                <Button
                  fullWidth
                  sx={{
                    height: "44px",
                    borderRadius: "10px",
                    textTransform: "initial",
                    textDecoration: "underline",
                    mt: 1,
                  }}
                >
                  Share this event
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Box
              sx={{
                height: "100%",
                bgcolor: "#FCEDFE",
                borderRadius: "8px",
                p: 3,
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <InputLabel
                sx={{
                  textAlign: "center",
                  color: "primary.main",
                  fontWeight: 700,
                }}
              >
                {data?.name}
              </InputLabel>
              <Typography
                className="three-line-ellipsis"
                sx={{ mt: 1, color: "#5b5b5b", fontWeight: 300 }}
              >
                {data?.description}
              </Typography>
              <Divider sx={{ my: 4, width: "100%" }} />
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
                >
                  <img src={Icons.location2} width={20} />
                  <Typography sx={{ color: "primary.main", fontWeight: 700 }}>
                    {data?.location}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                    mt: 1,
                  }}
                >
                  <img src={Icons.calendar} width={20} />
                  <Typography sx={{ color: "#5b5b5b", fontWeight: 300 }}>
                      {data?.frequency === "one-off" ? (
                <>{formatDateEvent(data?.event_time)}</>
              ) : (
                <>{`Every ${data?.event_day}s`}</>
              )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                    mt: 1,
                  }}
                >
                  <img src={Icons.time} width={20} />
                  <Typography sx={{ color: "#5b5b5b", fontWeight: 300, fontSize:isMobile ? '10px': '12px'  }}>
                    {data?.kick_off_time &&
                      convertTo12HourFormat(data?.kick_off_time)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                    mt: 1,
                  }}
                >
                  <img src={Icons.ticket} width={20} />
                  <Typography sx={{ color: "#5b5b5b", fontWeight: 300,  }}>
                    {" "}
                    Tickets from
                    <b style={{ color: "#75007E" }}>
                      {" "}
                      N{parseFloat(data?.ticket_min_price).toLocaleString()}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 4, width: "100%" }} />
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ color: "#5b5b5b", fontSize: "12px" }}>
                  Organized by:
                </Typography>
                <InputLabel
                  sx={{ color: "#000", fontSize: "14px", fontWeight: 700 }}
                >
                  {data?.organized_by}
                </InputLabel>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box>
              <Box
                sx={{
                  height: "200px",
                  background:
                    "linear-gradient(90deg, rgba(0,218,255,1) 0%, rgba(49,186,253,1) 16%, rgba(113,144,250,1) 38%, rgba(164,110,247,1) 58%, rgba(200,86,245,1) 76%, rgba(223,71,244,1) 91%, rgba(231,66,244,1) 100%)",
                  p: 0.3,
                  borderRadius: "12px",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    bgcolor: "#f4f4f4",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <MapContainer
                    restaurantAddress={data?.location}
                    variant="map"
                  />
                </Box>
              </Box>
              <Box align="right" sx={{ mt: -5, mr: 2 }}>
                <MapContainer
                  restaurantAddress={data?.location}
                  variant="button"
                />
              </Box>
              <Box sx={{ mt: 5 }}>
                <Typography sx={{ fontWeight: 300 }}>
                  Available Tickets
                </Typography>
                <Box sx={{ mt: 2 }}>
                  {data?.ticket?.map((tic, index) => (
                    <Box key={index} sx={{borderBottom:'0.1px solid #8f8f8f', pb:1.5, mb:1.5}}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <InputLabel sx={{ color: "#000", fontWeight: 700 }}>
                          {tic?.name} Ticket
                        </InputLabel>
                        <Typography>- Admit {tic?.admission_count}</Typography>
                      </Box>

                      <Typography sx={{color:'#8F8F8F', fontWeight:300, mt:0.5}}>
                        N{parseFloat(tic?.price)?.toLocaleString()}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EventDetails;

import axios from "axios";

const url = process.env.REACT_APP_API_URL;
// const url = " https://staging-api.vibezsup.com/api/v1";

const apiClient = axios.create({
  baseURL: url,
  timeout: 120000,
});

apiClient.interceptors.request.use(
  function (config) {
    // config.headers["Access-Control-Allow-Origin"] = "*";

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiClient.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});

const waitlist = (email) => {
  return apiClient.post("/join-waitlist", { email });
};

// ReservationLink

const getRestaurantDetails = (uid) => {
  return apiClient.get(`/customer/reservation/vendor/${uid}`);
};

const getReservationDetails = (id, menu) => {
  return apiClient.put(`/customer/reservation/link/details/${id}`, { menu });
};

const reservationBookings = (
  id,
  menu,
  timing_details,
  full_name,
  email_address,
  phone_number,
  max_guest_size,
  reservation_lead
) => {
  const payload = {
    menu,
    timing_details,
    full_name,
    email_address,
    phone_number,
    max_guest_size,
    reservation_lead,
    type: "future_reservation",
  };

  console.log(payload);

  return apiClient.post(`/customer/reservation/link/${id}`, payload);
};
const walkinBookings = (
  id,
  menu,
  full_name,
  email_address,
  phone_number,
  reservation_lead,
  payment_option
) => {
  const payload = {
    menu,
    full_name,
    email_address,
    phone_number,
    reservation_lead,
    type: "walk_in_reservation",
    payment_option,
  };

  console.log(payload);

  return apiClient.post(`/customer/reservation/link/${id}`, payload);
};

const checkPayment = (email, ref) => {
  return apiClient.get(
    `/customer/check/reservation/status?transaction_reference=${ref}&email=${email}`
  );
};

// =======EVENT SERVICE======================================================================================

const getEvent = () => {
  return apiClient.get("/event-management/");
};
const getStates = () => {
  return apiClient.get("/event-management/state");
};
const getSingleEvent = (id) => {
  return apiClient.get(`/event-management/${id}`);
};

const getRandomEvent = (id) => {
  return apiClient(`/event-management/other-events/${id}`);
};

const buyTicket = (id, ticket, full_name, email_address) => {
  const payload = {
    ticket,
    full_name,
    email_address,
  };
  return apiClient.post(`/event-management/tickets/${id}`, payload);
};

const checkTicketPayment = (reference, email) => {
  return apiClient.get(
    `/event-management/check/ticket/status?transaction_reference=${reference}&email=${email}`
  );
};




// =======EVENT FOR ONLINE RESERVATION LINK ==================================================

const getWidgetEvents = (vendor_id) =>{
  return apiClient.get(`/customer/event/tickets/${vendor_id}/:event`)
}
const buyWidgetTicket = (id, ticket, full_name, email_address, phone_number, event_id, ) => {
  const payload = {
    ticket,
    full_name,
    email_address,
    phone_number
  };
  return apiClient.post(`/customer/event/tickets/${id}/${event_id}`, payload);
};

export {
  waitlist,
  getRestaurantDetails,
  getReservationDetails,
  reservationBookings,
  walkinBookings,
  checkPayment,
  getEvent,
  getStates,
  getSingleEvent,
  getRandomEvent,
  buyTicket,
  checkTicketPayment,
  getWidgetEvents,
  buyWidgetTicket
};

import {
  Box,
  Typography,
  Button,
  Popover,
  MenuItem,
  Grid,
  InputLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EventNavbar from "../../Components/Common/EventNavbar";
import eventImg from "../../assets/img/ticket/hero.jpg";
import line from "../../assets/img/vectors/line.svg";
import textImg from "../../assets/img/ticket/text.png";
import Footer from "../../Components/Common/Footer";
import HomeBlog from "../../Components/Blog/HomeBlog";
import { IoIosArrowDown } from "react-icons/io";
import Icons from "../../constant/Icons";
import { Link } from "react-router-dom";
import { getEvent, getStates } from "../../assets/img/api";
import { formatDateEvent } from "../../Reservation/Pages/Reservation";
import Loader from "../../Components/Common/Loader";

const Event = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [states, setStates] = useState("Lagos State");
  const [stateList, setStateList] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [events, setEvents] = useState(null);

  const [limit, setLimit] = useState(8);

  const handleGetEvent = async () => {
    setIsLoading(true);
    await getEvent()
      .then((res) => {
        setIsLoading(false);
        setEvents(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleGetState = async () => {
    // setIsLoading(true);

    await getStates()
      .then((res) => {
        console.log(res);
        setStateList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetEvent();
    handleGetState();
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isLoading && <Loader />}
      <EventNavbar />
      <Box
        sx={{
          height: isMobile ? "250px" : "300px",
          background: `url(${eventImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          mt:12
        }}
      >
        <Box
          sx={{
            width: { lg: "75%", md: "75%", xs: "90%" },
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography
            sx={{
              color: "#FCEDFE",
              fontSize: { lg: "60px", md: "50px", sm: "30px", xs: "30px" },
              fontWeight: 700,
              lineHeight: { lg: "80px", md: "70px", sm: "40px", xs: "40px" },
              mt: 5,
            }}
          >
            Discover Exciting Local <br />
            Events{" "}
            <img
              src={textImg}
              width={isMobile ? 100 : 200}
              style={{ marginBottom: "-15px" }}
            />{" "}
            in your City!
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: { lg: "75%", md: "75%", xs: "90%" },
          margin: "0 auto",
          mt: 4,
        }}
      >
        <Button
          onClick={handleClick}
          aria-describedby={id}
          endIcon={<IoIosArrowDown style={{ fontSize: "12px" }} />}
          sx={{
            color: "#5b5b5b",
            fontSize: "12px",
            textTransform: "capitalize",
            border: "1px solid #dedede",
            transition: "0.2s all linear",
            px: 2,
          }}
        >
          Showing all events for :{" "}
          <span style={{ color: "#151515", transition: "0.2s all linear" }}>
            {" "}
            {states}
          </span>
        </Button>

        <Box sx={{ mt: 4, mb: 10 }}>
          {!events || events?.length === 0 ? (
            <></>
          ) : (
            <>
              <Grid container spacing={3}>
                {events?.event?.data?.map((e, index) => (
                  <Grid item lg={6} md={6} sm={12} xs={12} key={index}>
                    <Link to={`/event/${e.id}`}>
                      <EventCard data={e} />
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          <Box sx={{ mt: 6 }} align="center">
            <Button
              variant="outlined"
              startIcon={<img src={Icons.ticket2} />}
              sx={{
                fontSize: "12px",
                bgcolor: "#FCEDFE",
                fontWeight: 600,
                textTransform: "initial",
                borderRadius: "8px",
              }}
            >
              Load More Events
            </Button>
          </Box>
        </Box>
      </Box>
      <HomeBlog />

      <Box
        sx={{
          width: "70%",
          margin: "0 auto",
          background: `url('${line}')`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          py: { md: 5, xs: 0 },
        }}
      />
      <Footer />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 0, width: "130px" }}>
          {stateList?.map((state, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setStates(state);
                handleClose();
              }}
              selected={states === state}
              sx={{
                fontSize: "12px",
                color: "#151515",
                "&.Mui-selected": { color: "#5b5b5b" },
              }}
            >
              {state}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default Event;

export const EventCard = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box
        className="event_card"
        sx={{
          display: "flex",
          alignItems: "center",
          border: "0.7px solid #dedede",
          borderRadius: "20px",
          boxSizing: "border-box",
          p: 2,
          columnGap: 3,
          transition: "0.2s all linear",
          boxSizing: "border-box",
          "&:hover": {
            border: "2px solid",
          },
        }}
      >
        <Box
          sx={{
            width: "140px",
            height: isMobile ? "130px" : "170px",
            borderRadius: "12px",
            bgcolor: "#dedede",
            background: `url(${data?.banner})`,
            backgroundSize: "cover",
          }}
        />
        <Box sx={{ width: `calc(100% - 140px)` }}>
          <InputLabel
            sx={{
              fontWeight: 700,
              fontSize: isMobile ? "14px" : "16px",
              color: "#000",
            }}
          >
            {data?.event}
          </InputLabel>
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              alignItems: "center",
              mt: 1.5,
            }}
          >
            <img src={Icons.location2} width={isMobile ? 15 : 20} />
            <InputLabel
              sx={{
                color: "primary.main",
                fontWeight: 500,
                fontSize: isMobile ? "10px" : "12px",
              }}
            >
              {data?.location}
            </InputLabel>
          </Box>
          <Box
            sx={{ display: "flex", columnGap: 1, alignItems: "center", mt: 1 }}
          >
            <img src={Icons.calendar} width={isMobile ? 15 : 20} />
            <Typography
              sx={{
                color: "#5b5b5b",
                fontWeight: 300,
                fontSize: isMobile ? "10px" : "12px",
              }}
            >
              {data?.frequency === "one-off" ? (
                <>{formatDateEvent(data?.event_time)}</>
              ) : (
                <>{`Every ${data?.event_day}s`}</>
              )}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", columnGap: 1, alignItems: "center", mt: 1 }}
          >
            <img src={Icons.time} width={isMobile ? 15 : 20} />
            <Typography
              sx={{
                color: "#5b5b5b",
                fontWeight: 300,
                fontSize: isMobile ? "10px" : "12px",
              }}
            >
              {convertTo12HourFormat(data?.kick_off_time)}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", columnGap: 1, alignItems: "center", mt: 1 }}
          >
            <img src={Icons.ticket} width={isMobile ? 15 : 20} />
            <Typography
              sx={{
                color: "#5b5b5b",
                fontWeight: 300,
                fontSize: isMobile ? "10px" : "12px",
              }}
            >
              Tickets from
              <b style={{ color: "#75007E" }}>
                {" "}
                N{parseFloat(data?.ticket)?.toLocaleString()}
              </b>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const convertTo12HourFormat = (time) => {
  const [hours, minutes] = time.split(":"); // Split time into hours and minutes
  let hour = parseInt(hours, 10);
  const suffix = hour >= 12 ? "PM" : "AM"; // Determine AM/PM suffix
  hour = hour % 12 || 12; // Convert hour from 24-hour to 12-hour format
  return `${hour}:${minutes} ${suffix}`; // Return formatted time
};

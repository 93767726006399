// src/ThreeDCard.js
import React, { useRef } from "react";
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  useSpring,
} from "framer-motion";
import { Box } from "@mui/material";

// const ThreeDCard = ({ width, height, children, image }) => {
//   return (
//     <Box
//       sx={{
//         display: "grid",
//         width,
//         height,
//         placeContent: "center",
//         borderRadius: "24px",
//         p: 2,

//         backgroundColor: "#2d2e2f",
//       }}
//     >
//       <TiltCard height={height} image={image}>{children}</TiltCard>
//     </Box>
//   );
// };

const ROTATION_RANGE = 32.5;
const HALF_ROTATION_RANGE = ROTATION_RANGE / 2;

const ThreeDCard = ({ children, height, image , width}) => {
  const ref = useRef(null);

  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const xSpring = useSpring(x);
  const ySpring = useSpring(y);

  const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;

  const handleMouseMove = (e) => {
    if (!ref.current) return;

    const rect = ref.current.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
    const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;

    const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
    const rY = mouseX / width - HALF_ROTATION_RANGE;

    x.set(rX);
    y.set(rY);
  };

  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };

  return (
    <Box
      component={motion.div}
      sx={{
border: "7px solid #2d2e2f",
 outline: "1px solid #cccccc33",

     borderRadius: "24px",
     boxSizing:'border-box'
      }}
      ref={ref}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        transformStyle: "preserve-3d",
        transform,
      }}
    
    >
      <Box
      component={motion.div}
      sx={{  background: `url('${image}')`,
                  width:width,
                  height:height,
                  borderRadius: "24px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "bottom",
                  backgroundColor: "#04011533",
                  backgroundSize: "contain",
                   boxSizing:'border-box',
                  outline: "0.5px solid #cccccc33",}}
        style={{
          transform: "translateZ(75px)",
          transformStyle: "preserve-3d",
        }}
        className="absolute inset-4 grid place-content-center rounded-xl bg-white shadow-lg"
      >
        {children}
      </Box>
    </Box>
  );
};

export default ThreeDCard;

import React from "react";
import {
  Box,
  ListItemButton,
  Typography,

} from "@mui/material";
import bgImg from "../assets/img/banner.png";

const Container = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          height: "100vh",
          background: `#FCEDFE`,
          overflow: "hidden",
          boxSizing: "border-box",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box
          className="hide_scrollbar"
          sx={{
            // bgcolor: "#fff",
            width: { lg: "35%", md: "40%", sm: "50%", xs: "100%" },
            margin: "0 auto",
            height: "100vh",
            overflow: "scroll",
          }}
        >
          {children}

        </Box>
      </Box>
    </>
  );
};

export default Container;

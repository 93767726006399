import { AppBar, Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo/logo2.svg";
import CustomButton from "../CustomButton";
import buildIcon from "../../assets/img/vectors/building.svg";
import flash from "../../assets/img/vectors/flash.svg";
import newIcon from "../../assets/img/ticket/new.svg";

const Navbar = ({ isVendor }) => {
  const [color, setColor] = React.useState(false);

  const changeColor = () => {
    if (window.scrollY >= 60) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  
  return (
    <>
      <AppBar
        className={color ? " appbar_bg" : "appbar"}
        sx={{ backgroundColor: "#fff", boxShadow: "none" }}
      >
        <Box
          sx={{
            p: 3,
            width: { md: "75%", xs: "90%" },
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Link to="/">
            <img src={logo} width={50} />
          </Link>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
            <a href="https://events.vibezsup.com/">
              <Button
                endIcon={<img src={newIcon} width={22} />}
                sx={{
                  fontWeight: 700,
                  bgcolor: "#FCEDFE",
                  width: { lg: "182px", md: "182px", sm: "150px", xs: "130px" },
                  height: { lg: "50px", md: "50px", sm: "40px", xs: "40px" },
                  fontSize: { md: "14px", xs: "10px" },
                  textTransform: "initial",
                  borderRadius: "8px",
                }}
              >
                VIbezsup Events
              </Button>
            </a>
            {isVendor ? (
              <Link to="/">
                <CustomButton>
                  <img src={flash} style={{ marginRight: "8px" }} width={15} />
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { md: "14px", xs: "10px" },
                      fontWeight: 500,
                    }}
                  >
                    For Viberzs
                  </Typography>
                </CustomButton>
              </Link>
            ) : (
              <Link to="/vendor">
                <CustomButton>
                  <img
                    src={buildIcon}
                    style={{ marginRight: "8px" }}
                    width={15}
                  />
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { md: "14px", xs: "10px" },
                      fontWeight: 500,
                      whiteSpace: "nowrap",
                    }}
                  >
                    For Vendors
                  </Typography>
                </CustomButton>
              </Link>
            )}
          </Box>
        </Box>
      </AppBar>
    </>
  );
};

export default Navbar;
